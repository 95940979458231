import useAuth from "../../../hooks/useAuth";
import { isARMSTRONG, isATLAS, isDEMO, isPNC } from "../../../utils/inventoryRequests";

const defaultSetting =
  "identifiers,descriptive_info,origin_info,destination_info,warehouse_info,electrical_info,plumbing_info,mechanical_info,other_info,shipments,inventory_items,inventory_request,shopping_cart";

export default function useSettingsConfig(combinedHideFields) {
  const { user } = useAuth();
  const { company } = user;

  const columns = (company.settingsConfig || defaultSetting).split(",");
  const settingsList =
    isPNC || isATLAS || isARMSTRONG || isDEMO
      ? columns.filter(v => !["plumbing_info", "mechanical_info", "other_info"].includes(v))
      : columns;
  const settingsConfig = settingsList.filter(v => !combinedHideFields.includes(v));

  return settingsConfig;
}
