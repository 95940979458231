import { createTheme } from "@mui/material/styles";

const appThemeMui5 = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
        },
        elevation1: {
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
        },
      },
    },
  },
});

export default appThemeMui5;
