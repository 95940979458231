import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";
import AddIcon from "@mui/icons-material/Add";

import Header from "src/components/capture/ReceivingWizard/Header";
import ScanditScanner from "src/components/barcode/ScanditScanner/ScanditScanner";
import ScannedAssetDetails from "./ScannedAssetDetails";
import NewLocationModal from "./NewLocationModal";

import MoveLocationsImage from "../../../../assets/images/move_locations.png";

import useAuth from "src/hooks/useAuth";

const MoveLocationsModal = ({ open, onClose }) => {
  const { user } = useAuth();
  const [openScan, setOpenScan] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [openNewLocationModal, setOpenNewLocationModal] = useState(false);

  const [triggerScan, setTriggerScan] = useState(false);

  const [code, setCode] = useState(null);
  const [rackLocations, setRackLocations] = useState([]);
  const [oldRackLocations, setOldRackLocations] = useState([]);
  const [assetId, setAssetId] = useState(null);

  const handleAssetDetectedCode = (code) => {
    setScannedBarcode(code);
    handleToggleScan();
  };

  const handleToggleScan = () => {
    setOpenScan((v) => !v);
  };

  const handleScanNewLocation = () => {
    setTriggerScan(true);
    setOpenNewLocationModal(true);
  };

  const handleTransactionSuccess = () => {
    setOpenNewLocationModal(false);
    onClose();
    setScannedBarcode(null);
    setCode(null);
    setRackLocations([]);
    setOldRackLocations([]);
    setAssetId(null);
    setClientId(null);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
        <Box bgcolor="#f4f6f8">
          <Header
            title={scannedBarcode ? "Scanned Barcode Details" : "Inventory Move"}
            info={
              !scannedBarcode && (
                <PopupState variant="popover" popupId="options-menu">
                  {(popupState) => (
                    <>
                      <ButtonBase variant="contained" {...bindTrigger(popupState)}>
                        Advanced Options
                      </ButtonBase>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem>
                          Move Entire Location
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              )
            }
          />
        </Box>
        <DialogContent sx={{ background: "#f4f6f8" }}>
          {!scannedBarcode && (
            <>
              <Box display="flex" justifyContent="center" mt={2}>
                <Box
                  component="img"
                  src={MoveLocationsImage}
                  borderRadius={3}
                  width="100%"
                  maxWidth={300}
                  minHeight="100px"
                  alt="Move Locations"
                />
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <Typography maxWidth={300} fontWeight={500} align="center">
                  Scan the barcode on your item label to move it to a new location.
                </Typography>
              </Box>
              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleToggleScan}
                  size="large"
                >
                  Scan Barcode
                </Button>
              </Box>
            </>
          )}

          {openScan && (
            <ScanditScanner
              barcodeTrigger={user.company.settings.barcode_trigger}
              onDetected={scannedBarcode ? null : handleAssetDetectedCode}
              onClose={handleToggleScan}
            />
          )}

          {scannedBarcode && (
            <>
              <ScannedAssetDetails
                scannedBarcode={scannedBarcode}
                onClientIdExtracted={(clientId) => {
                  // Extracted clientId for WAREHOUSE_LOCATIONS_BY_CLIENT
                  setClientId(clientId);
                }}
                onAssetData={(assetData) => {
                  // Extracted asset data (assetId + rackLocations) for CREATE_INVENTORY_TRANSACTION_MUTATION
                  setCode(assetData.code);
                  setRackLocations(assetData.rackLocations);
                  setOldRackLocations(assetData.rackLocations);
                  setAssetId(assetData.id);
                }}
              />
              <Box mt={5} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleScanNewLocation}
                  size="large"
                >
                  Scan New Location
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      {openNewLocationModal && (
        <NewLocationModal
          open={openNewLocationModal}
          clientId={clientId}
          triggerScan={triggerScan}
          setTriggerScan={setTriggerScan}
          onClose={() => {
            setOpenNewLocationModal(false);
            setTriggerScan(false);
          }}
          onSuccess={handleTransactionSuccess}
          code={code}
          id={assetId}
          oldRackLocations={oldRackLocations}
          rackLocations={rackLocations.map((location) => ({
            ...location,
            rack_location: [],
          }))}
        />
      )}
    </>
  );
};

export default MoveLocationsModal;
