import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteModal from "../../../components/images/ConfirmDeleteModal";

export default function LogoUploadField({
  type,
  label,
  logoUrl,
  loading,
  onUpload,
  onRemove,
  hideUploadButton = false,
}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDelete = () => {
    onRemove(type);
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <Box
        p={2}
        border="1px solid #e0e0e0"
        borderRadius={1}
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="textSecondary">
            {label}
          </Typography>
          {logoUrl && !hideUploadButton && (
            <IconButton
              size="small"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={loading}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" minHeight={70}>
          {logoUrl ? (
            <img src={logoUrl} alt={`${type} logo`} style={{ maxWidth: "100%", maxHeight: 70 }} />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No logo uploaded yet
            </Typography>
          )}
        </Box>
        {!hideUploadButton && (
          <Box display="flex" justifyContent="center">
            <input
              accept="image/*"
              type="file"
              id={`logo-upload-${type}`}
              style={{ display: "none" }}
              onChange={e => {
                const file = e.target.files?.[0];
                if (file) {
                  onUpload(type, file);
                }
              }}
            />
            <label htmlFor={`logo-upload-${type}`}>
              <Button
                variant="outlined"
                component="span"
                startIcon={loading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                disabled={loading}
                fullWidth
              >
                Upload {label}
              </Button>
            </label>
          </Box>
        )}
      </Box>
      {showDeleteConfirm && (
        <ConfirmDeleteModal
          title={`Remove ${label}`}
          descr={`Are you sure you want to remove this ${label.toLowerCase()}?`}
          onClose={() => setShowDeleteConfirm(false)}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
