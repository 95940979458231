import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import ActionModal from "src/components/modals/action-modal";

function ConfirmDeleteModal({ title, descr, onClose, onDelete, applyLabel }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await onDelete();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <ActionModal
      maxWidth={440}
      title={
        <>
          <Box mr={1}>{title}</Box>
          <WarningIcon htmlColor="#ff9800" />
        </>
      }
      onClose={onClose}
    >
      <Box mt={1}>
        <Typography align="center" color="textSecondary">
          {descr}
        </Typography>
      </Box>
      <Box mt={2.5}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button data-id="delete-asset-modal" onClick={onClose} variant="outlined" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              data-id="confirm-delete"
              onClick={handleDelete}
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {applyLabel || "Delete"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ActionModal>
  );
}

export default ConfirmDeleteModal;
