import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "../../form/Input";
import useLabels from "../../../hooks/useLabels";

export default function OtherInfoFields({ fullWidth, display, disabledForm }) {
  const xs = fullWidth ? 12 : 6;
  const l = useLabels();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Miscellaneous
          </Typography>
        </Box>
      </Grid>
      {display.data_port_qty && (
        <Grid item xs={xs}>
          <Input
            type="autoExpand"
            name="dataPortQty"
            label={l.otherInfo.data_port_qty}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.bms && (
        <Grid item xs={xs}>
          <Input type="autoExpand" name="bms" label={l.otherInfo.bms} disabled={disabledForm} />
        </Grid>
      )}
      {display.other_utility && (
        <Grid item xs={xs}>
          <Input
            type="autoExpand"
            name="otherUtility"
            label={l.otherInfo.other_utility}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.move_notes && (
        <Grid item xs={xs}>
          <Input
            type="autoExpand"
            name="moveNotes"
            label={l.otherInfo.move_notes}
            disabled={disabledForm}
          />
        </Grid>
      )}
    </Grid>
  );
}
