import { useMemo } from "react";
import { useProjectInventory } from "../../contexts/projectInventory";

export const useAssetsFilterVariables = (order, hasImportedAssets) => {
  const { projectId, clientId } = useProjectInventory();

  return useMemo(() => {
    const variables = {};

    if (projectId) {
      variables.projectId = projectId;
    } else if (clientId) {
      variables.clientId = clientId;
    }

    if (order) {
      const [key, value] = order.split(".");
      variables.order = { [key]: value };
    }

    if (hasImportedAssets) {
      variables.hasImportedAssets = hasImportedAssets;
    }

    return variables;
  }, [projectId, clientId, order, hasImportedAssets]);
};
