import moment from "moment";
import snakeCase from "lodash/snakeCase";

import displayFields from "../constants/displayFields";

export const formatObject = values => Object.keys(values).filter(key => values[key]);

const SKIP_FIELDS = ["descriptive_info", "origin_info", "destination_info"];

export const getInitImage = asset =>
  asset.images && asset.images.length > 0 ? asset.images[0].id : "";

export const initialDescription = {
  description: "",
  origin_qty: "",
  dest_qty: "",
  dest_room: "",
};

export const initialPhysicalDimensions = {
  depth: "",
  width: "",
  height: "",
};

export const formatAssetInput = assetInput => {
  const { attachments, ...input } = assetInput;

  if (input.quantity !== null) {
    input.quantity = parseInt(input.quantity);
  }

  return input;
};

const initialCheckoutTask = [{}];

export const formatNotes = ({ notes = {}, finish = "" } = {}) => {
  // Add first initial description if array is empty
  if (!notes.descriptions?.length) {
    notes.descriptions = [initialDescription];
  }

  if (!notes.finishes?.length) {
    notes.finishes = [finish];
  }

  if (!notes.fabrics?.length) {
    notes.fabrics = [""];
  }

  if (!notes.laminates?.length) {
    notes.laminates = [""];
  }

  if (!notes.attic_stock) {
    notes.attic_stock = false;
  }

  if (!notes.physical_dimensions) {
    notes.physical_dimensions = [initialPhysicalDimensions];
  }

  if (!notes.checkout_task) {
    notes.checkout_task = initialCheckoutTask;
  } else {
    notes.checkout_task = notes.checkout_task.map(v => ({
      ...v,
      checkin_date: v.checkin_date ? moment(v.checkin_date).format("yyyy-MM-DDThh:mm") : "",
      checkout_date: v.checkout_date ? moment(v.checkout_date).format("yyyy-MM-DDThh:mm") : "",
      requested_by_date: v.requested_by_date
        ? moment(v.requested_by_date).format("yyyy-MM-DDThh:mm")
        : "",
    }));
  }

  return notes;
};

export const getFields = value => {
  return [
    ...formatObject(value.include || {}),
    ...formatObject(value.descriptive_info || {}),
    ...formatObject(value.origin_info || {}),
    ...formatObject(value.destination_info || {}),
    ...formatObject(value.warehouse_info || {}),
    ...formatObject(value.electrical_info || {}),
    ...formatObject(value.plumbing_info || {}),
    ...formatObject(value.mechanical_info || {}),
    ...formatObject(value.other_info || {}),
    ...formatObject(value.inventory_items || {}),
    ...formatObject(value.orders || {}),
    ...formatObject(value.reservation || {}),
  ];
};

export const getDisplayFields = value =>
  getFields(value).filter(item => !SKIP_FIELDS.includes(item));

export const fetchAssetValues = asset => {
  if (!asset?.id) return {};

  const assetFields = Object.keys(displayFields).reduce(
    (result, item) =>
      displayFields[item] && item !== "photo"
        ? { ...result, [snakeCase(item)]: asset[snakeCase(item)] }
        : result,
    {}
  );

  return {
    ...assetFields,
    active: asset.active,
    assetId: asset.assetId || "",
    barcode: asset.barcode || "",
    existingBuilding: asset.existingBuilding || "",
    newBuilding: asset.newBuilding || "",
    warehouseLocation: asset.warehouseLocation || "",
    palletLocation: asset.palletLocation || "",
    vaultLocation: asset.vaultLocation || "",
    existingFloor: asset.existingFloor || "",
    newFloor: asset.newFloor || "",
    existingRoom: asset.existingRoom || "",
    newRoom: asset.newRoom || "",
    description: asset.description || "",
    descriptionComments: asset.descriptionComments || "",
    manufacturer: asset.manufacturer || "",
    condition: asset.condition || "",
    color: asset.color || "",
    employeeName: asset.employeeName || "",
    originComments: asset.originComments || "",
    destinationComments: asset.destinationComments || "",
    originEmployeeName: asset.originEmployeeName || "",
    destinationEmployeeName: asset.destinationEmployeeName || "",
    originRoomNumber: asset.originRoomNumber || "",
    destinationRoomNumber: asset.destinationRoomNumber || "",
    quantity: asset.quantity || 0,
    destinationQuantity: asset.destinationQuantity || 0,
    disposition: asset.disposition || "",
    originCubbyNumber: asset.originCubbyNumber || "",
    notes: formatNotes(asset),
    departmentName: asset.departmentName || "",
    labName: asset.labName || "",
    capitalAssetNumber: asset.capitalAssetNumber || "",
    serialNumber: asset.serialNumber || "",
    vendorRequired: asset.vendorRequired || "",
    destinationNumber: asset.destinationNumber || "",
    installResponsibility: asset.installResponsibility || "",
    benchFloorHoodWallBsc: asset.benchFloorHoodWallBsc || "",
    siesmicAnchorRequired: asset.siesmicAnchorRequired || "",
    volts: asset.volts || "",
    ampsWatts: asset.ampsWatts || "",
    btu: asset.btu || "",
    hertz: asset.hertz || "",
    phase: asset.phase || "",
    nemaConfig: asset.nemaConfig || "",
    plugQuantity: asset.plugQuantity || "",
    standbyPower: asset.standbyPower || "",
    normalPower: asset.normalPower || "",
    dedicatedPower: asset.dedicatedPower || "",
    ups: asset.ups || "",
    standbyPowerSb: asset.standbyPowerSb || "",
    plugConnection: asset.plugConnection || "",
    hseCo: asset.hseCo || "",
    localGasTanks: asset.localGasTanks || "",
    hseVac: asset.hseVac || "",
    localVacPump: asset.localVacPump || "",
    icw: asset.icw || "",
    ihw: asset.ihw || "",
    pcwSupply: asset.pcwSupply || "",
    pcwReturn: asset.pcwReturn || "",
    ro: asset.ro || "",
    di: asset.di || "",
    floorSink: asset.floorSink || "",
    floorDrain: asset.floorDrain || "",
    solveWasteColl: asset.solveWasteColl || "",
    exhHeader: asset.exhHeader || "",
    vacPumpVentPlumbing: asset.vacPumpVentPlumbing || "",
    exhCeilingBlastGate: asset.exhCeilingBlastGate || "",
    exhSnorkelArmPlumbing: asset.exhSnorkelArmPlumbing || "",
    exhOther: asset.exhOther || "",
    listExhType: asset.listExhType || "",
    exhaust: asset.exhaust || "",
    vacPumpVentMechanical: asset.vacPumpVentMechanical || "",
    exhSnorkelArmMechanical: asset.exhSnorkelArmMechanical || "",
    cfm: asset.cfm || "",
    dataPortQty: asset.dataPortQty || "",
    bms: asset.bms || "",
    otherUtility: asset.otherUtility || "",
    moveNotes: asset.moveNotes || "",
    palletType: asset.palletType || "",
    serialNumberUrl: asset.serialNumberUrl,
    capitalAssetNumberUrl: asset.capitalAssetNumberUrl,
    quantity_ordered: asset.quantity_ordered,
    quantity_received: asset.quantity_received,
    tssNumber: asset.tssNumber,
    quantityOrdered: asset.quantityOrdered,
    quantityReceived: asset.quantityReceived,
    warranty: asset.warranty,
    itemCode: asset.itemCode,
    unitCost: asset.unitCost,
    poNumber: asset.poNumber,
    category: asset.category,
    subcategory: asset.subcategory,
    minimumQuantity: asset.minimumQuantity,
    receivingReportNumber: asset.receivingReportNumber,
    weight: asset.weight,
    billingCategory: asset.billingCategory,
    osdNotes: asset.osdNotes,
    generalNotes: asset.generalNotes,
    copyLocation: false,
    skipNew: false,
    copyAll: false,
  };
};
