import * as Yup from "yup";
import camelCase from "lodash/camelCase";
import useFields from "../../../../hooks/useFields";

const exclude = [
  "item_id",
  "photo",
  "available_quantity",
  "rack_locations",
  "destination_room_number",
  "attachments",
  "barcode",
  "barcode_number",
  "power",
  "receiving_report_number",
  "weight",
  "minimum_quantity",
  "warehouse_quantity",
  "reserved_quantity",
  "unit_cost",
  "item_id",
  "warranty",
  "po_number",
  "created_at",
  "updated_at",
  "osd_notes",
  "general_notes",
];

const useItemSchema = display => {
  const fields = useFields();
  const validationList = fields
    .filter(v => v.enabled)
    .flatMap(v => v.list)
    .map(([key]) => key)
    .filter(key => display[key])
    .filter(key => !exclude.includes(key))
    .map(v => [v, camelCase(v)]);
  let validate = {};

  validationList.forEach(([key, camelKey]) => {
    validate[camelKey] = Yup.string()
      .required("Required")
      .nullable();
  });

  if (display.barcode_number) {
    validate.barcode = Yup.string()
      .required("Required")
      .nullable();
  }

  if (display.available_quantity) {
    validate.availableQuantity = Yup.number()
      .moreThan(0, "Required")
      .required("Required");
  }

  return Yup.object().shape(validate);
};

export { useItemSchema };
