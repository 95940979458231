import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const styles = {
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: 0,
    boxSizing: "border-box",
    borderBottom: "1px solid #DDDBDA",
    background: "white",
    height: 58,
    padding: "4px 8px",
    textAlign: "left",
    width: "100%",
    overflow: "hidden",
  },
  imageStub: {
    width: 67,
    height: 50,
    marginRight: 16,
    borderRadius: 4,
  },
  contentStub: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

export default function AssetItemSkeleton({ checkbox = false }) {
  return (
    <ButtonBase style={styles.root} variant="outlined" disabled>
      {checkbox && (
        <Box px="4px">
          <Skeleton variant="rectangular" width={24} height={24} style={{ marginRight: 16 }} />
        </Box>
      )}
      <Skeleton variant="rectangular" style={styles.imageStub} />
      <div style={styles.contentStub}>
        <Skeleton variant="text" width="80px" height={24} />
        <Skeleton variant="text" width="120px" height={24} />
      </div>
    </ButtonBase>
  );
}
