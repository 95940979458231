import React from "react";
import Box from "@mui/material/Box";

import { getBackgroundImage } from "../../../utils/images";

export function getAssetItemImage(images) {
  return images?.length > 0 ? images.find(v => v.default) || images[0] : null;
}

export default function AssetItemImage({ images, ...props }) {
  const image = getAssetItemImage(images);

  return (
    <Box
      width={67}
      minWidth={67}
      height={50}
      minHeight={50}
      borderRadius="4px"
      bgcolor="#f4f6f8"
      sx={{
        backgroundImage: getBackgroundImage(image?.url, "width", 120),
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
      {...props}
    />
  );
}
