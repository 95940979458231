import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useCustomFields } from "../../../contexts/customFields";

const descriptionStyles = {
  maxWidth: "100%",
  maxHeight: "100%",
  textOverflow: "ellipsis",
};

const getDimension = (value = []) =>
  value
    .filter(v => v.width || v.depth || v.height)
    .map(field =>
      [`${field.width}″&nbsp;W`, `${field.depth}″&nbsp;D`, `${field.height}″&nbsp;H`].join(
        "&nbsp;x&nbsp;"
      )
    )
    .join(", ");

export default function AssetCardTitle({ asset }) {
  const [display] = useCustomFields();

  return (
    <Box mt={2}>
      {display.sc_item_code && (
        <Typography mt={1} fontWeight={500} align="center" color="textPrimary">
          {asset.itemCode}
        </Typography>
      )}
      <Typography
        align="center"
        style={descriptionStyles}
        dangerouslySetInnerHTML={{
          __html: [
            [
              display.sc_manufacture && asset.manufacturer,
              display.sc_description && asset.description,
            ]
              .filter(v => v)
              .join(" "),
            [display.sc_model && asset.model, getDimension(asset.dimensions)]
              .filter(v => v)
              .join(" "),
          ]
            .filter(v => v)
            .join(", "),
        }}
      />
      <Typography align="center">
        {[display.sc_po_number && asset.poNumber, display.sc_color && asset.color]
          .filter(v => v)
          .join(" - ")}{" "}
        {display.sc_condition && asset.condition && `(${asset.condition})`}
      </Typography>
      {asset.descriptionComments && display.sc_description_comments && (
        <Typography align="center">({asset.descriptionComments})</Typography>
      )}
    </Box>
  );
}

AssetCardTitle.propTypes = {
  asset: PropTypes.object.isRequired,
};
