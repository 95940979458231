import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useAuth from "../../hooks/useAuth";
import ReceivingWizardModal from "../../components/capture/ReceivingWizard/ReceivingWizardModal";
import { useAssetsCollection } from "../../contexts/assetsCollection";
import useQueryParams from "../../hooks/useQueryParams";
import SelectProjectAttentionModal from "./SelectProjectAttentionModal";

export default function CaptureActionsMobile({
  disabled,
  onSave,
  onCreate,
  onDuplicate,
  projectId,
  disableSave,
}) {
  const [isAttentionOpen, setIsAttentionOpen] = useState(false);
  const params = useQueryParams();
  const { handeRefetch } = useAssetsCollection();
  const history = useHistory();
  const { user } = useAuth();
  const { settings } = user.company;

  const handleReceivingToggle = () => {
    if (params.get("receiving")) {
      handeRefetch();
      history.push({ search: "" });
    } else {
      const newParams = new URLSearchParams(params);
      newParams.delete("isFormModalOpen");
      newParams.set("step", "details");
      newParams.set("receiving", "true");
      history.push({ search: newParams.toString() });
    }
  };

  const handleCreate = () => {
    if (!projectId) {
      setIsAttentionOpen(true);
      return;
    }

    if (settings.receiving_wizard) {
      handleReceivingToggle();
    } else {
      onCreate();
    }
  };

  const visibleButtons = [onDuplicate && !settings.receiving_wizard, onSave, onCreate].filter(
    Boolean
  );

  const buttonSize = visibleButtons.length > 0 ? 12 / visibleButtons.length : 12;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          {onDuplicate && !settings.receiving_wizard && (
            <Grid item xs={buttonSize}>
              <Button
                disabled={disabled || !onDuplicate || disableSave}
                id="duplicateAsset"
                data-id="duplicate-asset"
                fullWidth
                variant="outlined"
                onClick={onDuplicate}
              >
                Duplicate
              </Button>
            </Grid>
          )}
          {onSave && (
            <Grid item xs={buttonSize}>
              <Button
                disabled={disabled || !onSave || disableSave}
                id="saveAsset"
                data-id="save-asset"
                fullWidth
                variant="outlined"
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>
          )}
          {onCreate && (
            <Grid item xs={buttonSize}>
              <Button
                disabled={disabled || !onCreate}
                id="addNewAsset"
                data-id="add-new-asset"
                fullWidth
                variant="contained"
                onClick={handleCreate}
                color="primary"
              >
                Add New
              </Button>
            </Grid>
          )}
          {params.get("receiving") && <ReceivingWizardModal onToggle={handleReceivingToggle} />}
        </Grid>
      </CardContent>
      <SelectProjectAttentionModal
        open={isAttentionOpen}
        onClose={() => setIsAttentionOpen(false)}
      />
    </Card>
  );
}
