import React, { useEffect, useState } from "react";

import { getInitImage } from "../utils/asset";
import { useProjectInventory } from "./projectInventory";
import { useAssetsCollection } from "./assetsCollection";
import { useClients } from "./clients";

const AssetsContext = React.createContext(null);

export const AssetsProvider = ({ children, assetId: defaultAssetId }) => {
  const { clients } = useClients();
  const [selectedImage, setSelectedImage] = useState("");

  const { clientId, projectId, setClientId, setProjectId } = useProjectInventory();

  const {
    loading,
    collection,
    handeRefetch,
    onLoadMore,
    metadata,
    loadingMutation,
    removeAsset,
    createAsset,
    updateAsset,
    removeImage,
    createImage,
    updateImage,
    bulkCreateImages,
    search,
    setSearch,
    limit,
    setLimit,
  } = useAssetsCollection();

  const [currentAssetId, setCurrentAssetId] = useState(defaultAssetId);

  const currentAsset = collection.find(asset => asset.id === currentAssetId) || collection[0];

  useEffect(() => {
    if (currentAsset?.id && !projectId && !clientId) {
      setClientId(currentAsset.client?.id);
      setProjectId(currentAsset.project?.id);
    }
  }, [currentAsset?.id]);

  const setCurrentAsset = selectedAsset => {
    const collectionAsset = collection.find(item => item.id === selectedAsset?.id);

    const asset = collectionAsset || selectedAsset;

    if (asset) {
      setCurrentAssetId(asset.id);
      setSelectedImage(getInitImage(asset));
    } else {
      setCurrentAssetId(null);
      setSelectedImage("");
    }
  };

  const handleCreateAsset = ({ variables, onSuccess, onFailure }) => {
    return createAsset({
      variables: variables,
      onSuccess: ({ asset }) => {
        if (onSuccess) onSuccess({ asset });
        setCurrentAsset(asset);
      },
      onFailure,
    });
  };

  const handleRemoveAsset = ({ variables, onSuccess, onFailure }) => {
    return removeAsset({
      variables,
      onSuccess: ({ asset }) => {
        const nextAsset = collection.find(item => item.id !== asset.id);
        setCurrentAsset(nextAsset);
        onSuccess && onSuccess({ asset });
      },
      onFailure,
    });
  };

  const value = {
    // Inventory Params
    clientId,
    projectId,

    // Collection
    loading,
    collection,
    refetchAssets: handeRefetch,
    onLoadMore,
    setLimit,
    limit,
    search,
    setSearch,
    totalCount: metadata.totalCount,

    // Update asset
    currentAsset,
    setCurrentAsset,
    clients,
    selectedImage,

    onSelectImage: setSelectedImage,

    loadingMutation,
    removeAsset: handleRemoveAsset,
    createAsset: handleCreateAsset,
    updateAsset,
    removeImage,
    createImage,
    updateImage,
    bulkCreateImages,
  };

  return <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>;
};

export const useAssets = () => React.useContext(AssetsContext);

export const useFieldSettings = () => {
  const { currentProject } = useClients();

  return currentProject?.fieldSettings
    ? JSON.parse(currentProject?.fieldSettings.replaceAll("=>", ":"))
    : {};
};
