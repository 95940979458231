import React from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import UndoIcon from "@mui/icons-material/Undo";
import { useUndo } from "../../contexts/undo";
import useAssetsTable from "../../hooks/useAssetsTable";

export default function Undo() {
  const { handleBulkUpdate } = useAssetsTable();
  const { undo } = useUndo();

  const handleUndo = () => {
    const item = undo();
    if (item) {
      handleBulkUpdate(item[0], false);
    }
  };

  return (
    <MenuItem data-id="undoAction" onClick={handleUndo}>
      <Box mr={1}>
        <UndoIcon />
      </Box>
      Undo
    </MenuItem>
  );
}
