import React, { useState } from "react";
import Box from "@mui/material/Box";
import { FixedSizeList as SizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import clsx from "clsx";

import AssetsWrap from "../../../AssetsWrap";
import SortAssets from "../../../SortAssets";
import AssetListItem from "./AssetListItem";
import useInfinityScroll from "../../../../../hooks/capture/useInfinityScroll";
import { useAssetsCollection } from "../../../../../hooks/capture/useAssetsCollection";
import { useAssetsFilterVariables } from "../../../../../hooks/capture/useAssetsFilterVariables";
import useAssetsFilter from "../../../../../hooks/capture/useAssetsFilter";

const ITEM_HEIGHT = 58;
const LIST_HEIGHT = 300;
const type = "Asset";

export default function SearchItems() {
  const initialOrder = localStorage.getItem("_order");
  const [order, setOrder] = useState(initialOrder || "item_id.desc");
  const variables = useAssetsFilterVariables(order);
  const { filter, search, setSearch, limit } = useAssetsFilter(variables, type);

  const [{ data, loading }, { onLoadMore }] = useAssetsCollection(filter);
  const { collection = [], metadata } = data?.assetsCollection || {};
  const totalCount = metadata?.totalCount || 0;

  const scroll = { entities: collection, totalCount, rowsCount: limit, onLoadMore, loading };
  const { itemCount, loadMoreItems, isItemLoaded } = useInfinityScroll(scroll);
  const bulkProps = {};

  return (
    <AssetsWrap height={undefined} loading={loading} search={search} setSearch={setSearch}>
      <SortAssets bulkProps={bulkProps} order={order} setOrder={setOrder} />
      <Box display="flex" flexDirection="column" style={{ height: LIST_HEIGHT, overflowY: "auto" }}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <SizeList
              className={clsx({ "no-scrollbars": false })}
              ref={ref}
              height={LIST_HEIGHT}
              onItemsRendered={onItemsRendered}
              itemData={collection}
              itemCount={itemCount}
              itemSize={ITEM_HEIGHT}
              width="100%"
            >
              {({ style, data, index }) => <AssetListItem order={order} asset={data[index]} style={style} />}
            </SizeList>
          )}
        </InfiniteLoader>
      </Box>
    </AssetsWrap>
  );
}
