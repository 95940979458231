import React from "react";
import Box from "@mui/material/Box";

export default function ReceivingReportNumberComponent({ value }) {
  if (!value) return "";

  return (
    <Box height="100%" width="100%" sx={{ overflowY: "auto" }}>
      {value.split(", ").map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </Box>
  );
}
