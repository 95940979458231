import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { useCustomFields } from "../../../../contexts/customFields";
import Step1 from "./ShipmentFields";
import useMutation from "src/hooks/useMutation";
import { UPDATE_SHIPMENT_MUTATION } from "src/constants/graphql/mutations";
import useQueryParams from "src/hooks/useQueryParams";

const getShipmentSchema = display => {
  let validate = {};
  if (display.total_receiving_units) {
    validate.totalPieces = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.customer_po_number) {
    validate.customerPoNumber = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.carrier_name) {
    validate.carrierName = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.vendor_name) {
    validate.vendorName = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.order_number) {
    validate.orderNumber = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.bol) {
    validate.bol = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.total_weight) {
    validate.totalWeight = Yup.string()
      .required("Required")
      .nullable();
  }
  if (display.tracking_number) {
    validate.trackingNumber = Yup.string()
      .required("Required")
      .nullable();
  }
  return validate;
};

export default function Shipment({ shipment, initialValues }) {
  const params = useQueryParams();
  const id = params.get("id");
  const history = useHistory();
  const [display] = useCustomFields();
  const schema = Yup.object().shape(getShipmentSchema(display));
  const [updateShipment] = useMutation(UPDATE_SHIPMENT_MUTATION);

  const handleSave = async ({ weightUnit, weightUnits, totalWeight, ...input }) => {
    const weight = weightUnits === "Kgs" ? totalWeight * 2.20462 : totalWeight;
    await updateShipment({
      variables: {
        id,
        input: {
          ...input,
          weightUnits: "Lbs",
          totalWeight: weight,
        },
      },
    });
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("step", "items");
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box minHeight={200}>
            <Step1 id={id} shipment={shipment} display={display} isSubmitting={isSubmitting} />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
