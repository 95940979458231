import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ASSETS_COLLECTION_QUERY } from "src/constants/graphql/queries";
import { LinearProgress, Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ItemId from "src/views/shopping/Orders/ItemId";

export default function ScannedAssetDetails({ scannedBarcode, onClientIdExtracted, onAssetData }) {
  const { data, loading, error } = useQuery(ASSETS_COLLECTION_QUERY, {
    variables: {
      search: scannedBarcode,
    },
    skip: !scannedBarcode,
  });

  const [openItemIdModal, setOpenItemIdModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const assets = data?.assetsCollection?.collection || [];

  useEffect(() => {
    if (assets.length > 0) {
      const firstAsset = assets[0];

      if (firstAsset?.client?.id && onClientIdExtracted) {
        onClientIdExtracted(firstAsset.client.id);
      }

      if (onAssetData) {
        const assetData = {
          id: firstAsset.id,
          code: firstAsset.assetId,
          rackLocations: firstAsset.rackLocations,
        };
        onAssetData(assetData);
      }
    }
  }, [assets, onClientIdExtracted, onAssetData]);

  if (loading) {
    return (
      <Box mt={2}>
        <LinearProgress />
      </Box>
    );
  }

  if (error || assets.length === 0) {
    return (
      <Box mt={2}>
        <Typography color="error">Error loading asset data or no asset found.</Typography>
      </Box>
    );
  }

  const formatRackLocations = (rackLocations) => {
    if (!rackLocations || rackLocations.length === 0) {
      return "No storage locations";
    }

    return rackLocations
      .map((location) => {
        const rackLocation = location.rack_location?.[0] || "N/A";
        const subQty = location.sub_qty ? `(${location.sub_qty})` : "";
        return `${rackLocation} ${subQty}`;
      })
      .join(", ");
  };

  const handleOpenItemIdModal = (asset) => {
    setSelectedAsset(asset);
    setOpenItemIdModal(true);
  };

  return (
    <>
      <Table aria-label="Asset Details Table">
        <TableHead>
          <TableRow>
            <TableCell>Item ID</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Current Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset) => (
            <TableRow key={asset.id}>
              <TableCell>
                <ItemId asset={asset} />
              </TableCell>
              <TableCell>
                {asset?.photo ? (
                  <img src={asset.photo} alt="Asset" style={{ maxWidth: 100 }} />
                ) : (
                  "No image"
                )}
              </TableCell>
              <TableCell>{asset.description}</TableCell>
              <TableCell>{formatRackLocations(asset.rackLocations)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {openItemIdModal && selectedAsset && (
        <ItemId asset={selectedAsset} onClose={() => setOpenItemIdModal(false)} />
      )}
    </>
  );
}
