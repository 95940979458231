import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import useAuth from "../../../hooks/useAuth";
import {
  ASSET_AUDIT_LOGS_QUERY,
  ASSET_REPLAY_LOGS_QUERY,
  ASSETS_COLLECTION_RESTOCK_INVENTORY_QUERY,
} from "../../../constants/graphql/queries";
import {
  ADD_SHOPPING_INVENTORY_ITEM_MUTATION,
  CREATE_INVENTORY_TRANSACTION_MUTATION,
} from "../../../constants/graphql";
import useMutation from "../../../hooks/useMutation";
import useNotification from "../../../hooks/notification";
import { formRef } from "../../../views/capture/Form";
import useQueryParams from "../../../hooks/useQueryParams";
import { getAssetLabel } from "../../SearchAutocomplete";
import useWarehouseLocations from "../../../hooks/warehouse/useWarehouseLocations";
import ActionModal from "../action-modal";
import Fields, { getRestockTotal } from "./Fields";

const schema = Yup.object().shape({
  locations: Yup.array().of(
    Yup.object().shape({
      rack_location: Yup.string().required("Required"),
    })
  ),
});

const initialLocation = { new: true, sub_qty: 0 };

export default function InventoryTransactionMultipleModal({
  open,
  onClose,
  onSuccess,
  title,
  code: defaultCode,
  asset,
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { projectId, clientId } = useParams();
  const params = useQueryParams();
  const shipmentItemId = params.get("shipmentItemId");
  const [loading, setLoading] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [search, setSearch] = useState(asset ? getAssetLabel(asset) : "");
  const [code, setCode] = useState(defaultCode);
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);
  const [addInventoryItem] = useMutation(ADD_SHOPPING_INVENTORY_ITEM_MUTATION);
  const variables = clientId
    ? { clientId, search, type: "Asset" }
    : { projectId, search, type: "Asset" };
  const {
    loading: loadingAssets,
    data: { assetsCollection = [] } = {},
    refetch,
  } = useQuery(ASSETS_COLLECTION_RESTOCK_INVENTORY_QUERY, { variables });
  const collection = assetsCollection.collection || [];
  const { notifySuccess, notifyError } = useNotification();
  const { warehouseLocations, loading: loadingWarehouseLocations } = useWarehouseLocations(
    asset.client.id
  );

  const initialValues = useMemo(() => {
    if (asset) {
      return {
        id: asset.id,
        code: asset.assetId,
        locations: asset?.rackLocations.length ? asset.rackLocations : [initialLocation],
      };
    } else {
      return { code: defaultCode, locations: [] };
    }
  }, [asset, defaultCode]);

  const toggleEditLocation = () => {
    setEditLocation(v => !v);
  };

  const onSubmit = values => {
    const locations = user.company.settings.warehouse_restock_modal
      ? values.locations.map(v => ({
          sub_qty: v.restock_sub_qty ? v.restock_sub_qty + v.sub_qty : v.sub_qty,
          rack_location: v.rack_location,
          warehouse: v.warehouse,
        }))
      : values.locations.map(v => ({
          sub_qty: v.restock_sub_qty ? v.restock_sub_qty + v.sub_qty : v.sub_qty,
          rack_location: v.rack_location,
        }));
  
    const oldRackLocations = initialValues.locations.map(v => ({
      rack_location: v.rack_location,
      warehouse: v.warehouse,
      sub_qty: v.sub_qty,
    }));
    
    const rackLocationsChanges = values.locations
      .filter(v => v.restock_sub_qty)
      .map(v => ({
        sub_qty: v.restock_sub_qty,
        rack_location: v.rack_location,
        warehouse: v.warehouse,
      }));
  
    const restockTotal = getRestockTotal(values.locations);
    setLoading(true);
    const variables = {
      code: values.code,
      notes: values.notes || "",
      senderName: values.senderName || "",
      carrierName: values.carrierName || "",
      trackingNumber: values.trackingNumber || "",
      orderNumber: values.orderNumber || "",
      poNumber: values.poNumber || "",
      quantity: restockTotal || 0,
      rackLocations: locations,
      rackLocationsChanges,
      oldRackLocations,
      id: values.id,
    };  
    if (shipmentItemId) {
      variables.shipmentItemId = shipmentItemId;
    }
    const refetchQueries = [
      user.company.settings.asset_replay_logs
        ? {
            query: ASSET_REPLAY_LOGS_QUERY,
            variables: { assetId: values.id },
          }
        : {
            query: ASSET_AUDIT_LOGS_QUERY,
            variables: { assetId: values.id, action: "replenish_inventory" },
          },
    ];
    return createTransactionMutation({
      variables,
      refetchQueries,
      onSuccess: data => {
        setLoading(false);
        notifySuccess("Asset successfully updated!");
        onSuccess(data, values);
        setCode(null);
      },
      onFailure: errors => {
        notifyError(errors.submit);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (formRef?.current?.dirty) {
      const saveButton = document.querySelector("#saveAsset");
      if (saveButton) {
        saveButton.click();
      }
    }
  }, []);

  return (
    <ActionModal open={open} maxWidth={900} onClose={onClose} title={title} disableTransition disableBackdropClose={true}>
      <Box height={10}>{loadingAssets && <LinearProgress className="mt-2" />}</Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ values, setFieldValue, handleSubmit }) => {
          const selected = collection.find(v => v.id === values.id);
          const restockSubQtyExist = values.locations.some(
            v => typeof v.restock_sub_qty === "number" && v.restock_sub_qty !== 0
          );
          const disabled = loading || (!restockSubQtyExist && !editLocation);

          const restockAddToCart = () => {
            const restockTotal = getRestockTotal(values.locations);
            onSubmit(values).then(() => {
              if (restockTotal > 0) {
                addInventoryItem({
                  variables: {
                    assetId: values.id,
                    quantity: restockTotal,
                    projectId,
                  },
                });
              }
            });
          };

          return (
            <Box
              minHeight={340}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Grid container spacing={1}>
                {/*}
                <Grid item xs={12}>
                  <SearchAutocomplete
                    size="small"
                    selected={code}
                    value={search}
                    onChange={setSearch}
                    collection={collection}
                    selectAsset={asset => {
                      setFieldValue("id", asset.id);
                      setFieldValue("code", asset.assetId);
                      setFieldValue(
                        "locations",
                        asset.rackLocations?.length ? asset.rackLocations : [initialLocation]
                      );
                    }}
                  />
                </Grid>
                */}
                {selected && (
                  <Fields
                    editLocation={editLocation}
                    values={values}
                    asset={selected}
                    warehouseLocations={warehouseLocations}
                    loadingWarehouseLocations={loadingWarehouseLocations}
                  />
                )}
              </Grid>
              <Box pt={5} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  {!settings.simple_inventory_modal && values.code && (
                    <Button
                      style={{ textTransform: "none" }}
                      color="primary"
                      variant="text"
                      onClick={toggleEditLocation}
                    >
                      Edit Location(s)
                    </Button>
                  )}
                </Box>

                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{
                      mr: "1px",
                      height: 36,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    Submit
                  </Button>

                  {!settings.simple_inventory_modal && (
                    <Box variant="contained" aria-label="split button">
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {popupState => (
                          <React.Fragment>
                            <Button
                              disabled={disabled}
                              variant="contained"
                              color="primary"
                              {...bindTrigger(popupState)}
                              sx={{
                                px: 0,
                                height: 36,
                                minWidth: 40,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            >
                              <KeyboardArrowDownIcon
                                sx={{ transform: popupState.open ? undefined : "rotate(180deg)" }}
                              />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  restockAddToCart();
                                  popupState.close();
                                }}
                              >
                                Submit + Add to Cart
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </ActionModal>
  );
}
