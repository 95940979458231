import React from "react";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoading } from "./LoadingProvider";

const styles = {
  container: {
    position: "sticky",
    bottom: "10px",
    width: "100%",
    left: 0,
    zIndex: 99,
    backgroundColor: "white",
  },
};

export default function FormActions({ removing, toggleRemoveItem }) {
  const { submitForm, isSubmitting } = useFormikContext();
  const { loading } = useLoading();

  const isDeleteDisabled = removing || loading;
  const isSaveDisabled = loading || isSubmitting;

  return (
    <Box sx={styles.container}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            disabled={isDeleteDisabled}
            startIcon={removing && <CircularProgress size={22} />}
            onClick={toggleRemoveItem}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={submitForm}
            disabled={isSaveDisabled}
            startIcon={isSubmitting && <CircularProgress size={22} />}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
