import React, { useRef } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectInfoModal from "src/components/project/CustomerFieldsModal";
import CustomerSetup from "src/components/project/settings/CustomerSetup";
import KeyDetails from "src/components/project/settings/KeyDetails";
import ContactDetails from "src/components/project/settings/ContactDetails";
import ReportSettings from "src/components/project/settings/ReportSettings";
import useMutation from "src/hooks/useMutation";
import {
  UPDATE_PROJECT_MUTATION,
  UPDATE_PROJECT_SETTINGS_MUTATION,
} from "src/constants/graphql/mutations";
import useNotification from "src/hooks/notification";
import { getProjectFieldSettingsVariables, getProjectVariables } from "src/utils/profile/settings";
import { useProject } from "../ProjectProvider";
import BillingSettings from "./BillingSettings";

const styles = {
  actionButton: {
    minWidth: 120,
  },
};

export default function Settings({ onClose }) {
  const { projectId } = useParams();
  const { notifySuccess } = useNotification();
  const [open, setOpen] = React.useState(false);
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION);
  const [updateProjectSettings] = useMutation(UPDATE_PROJECT_SETTINGS_MUTATION);
  const { project, client } = useProject();
  const initialValues = { ...project, active: !project.disabled, clientName: client.name };

  const saveButtonRef = useRef();

  const handleSubmit = async values => {
    if (initialValues.billingType !== values.billingType) {
      await updateProjectSettings({
        variables: {
          projectId,
          fieldSettings: getProjectFieldSettingsVariables(
            values.billingType,
            project.fieldSettings
          ),
        },
      });
    }

    const variables = getProjectVariables({ ...values, id: projectId });
    return updateProject({
      variables,
      onSuccess() {
        notifySuccess("Project has been updated");
      },
    });
  };

  const triggerSave = () => {
    if (saveButtonRef.current) {
      saveButtonRef.current.click();
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <KeyDetails />
            </Box>
            <Box mt={2}>
              <CustomerSetup onToggleProjectInfoModal={triggerSave} />
            </Box>
            <Box mt={2}>
              <ContactDetails />
            </Box>
            {values.storageEnabled && (
              <Box mt={2}>
                <ReportSettings project={project} />
              </Box>
            )}
            {values.storageEnabled && (
              <Box mt={2}>
                <BillingSettings />
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button variant="outlined" color="primary" sx={styles.actionButton} onClick={onClose}>
                Close
              </Button>
              <Button
                ref={saveButtonRef}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ ml: 1, ...styles.actionButton }}
                disabled={isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={22} />}
              >
                Save
              </Button>
            </Box>
            {open && <ProjectInfoModal onClose={handleToggle} />}
          </form>
        </>
      )}
    </Formik>
  );
}
