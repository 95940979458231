import React from "react";
import Typography from "@mui/material/Typography";

export default function AssetItemLabel({ asset, selectedField }) {
  if (!selectedField) return null;

  const formatDimensions = (dimensions) => {
    if (!dimensions.length) return "N/A";
    return dimensions
      .map(({ width, depth, height }) => [width, depth, height].join("x"))
      .join(", ");
  };

  return (
    <Typography 
      color="primary"
      lineHeight={1.2}
      fontWeight={500}
      variant="body2"
    >
      {selectedField === "dimensions" 
        ? formatDimensions(asset[selectedField])
        : asset[selectedField] || "N/A"}
    </Typography>
  );
}
