import useAuth from "../../../hooks/useAuth";
import {
  getWarehouseInfo,
  getOrders,
  getInventoryRequest,
  getshoppingCart,
} from "../../../constants/fields";

const filterFields = (fields, excludeFields) =>
  fields.filter(field => !excludeFields.includes(field[0]));

export default function useFields(inventoryItems) {
  const { user } = useAuth();
  const { settings } = user.company;

  const warehouseInfo = getWarehouseInfo({
    availableQuantity: settings.quantity_label,
  });
  const orders = getOrders({ dateName: settings.date_name });
  const inventoryRequest = getInventoryRequest({
    commentsName: settings.inventory_request_comments_name,
  });
  const shoppingCart = getshoppingCart({
    ...settings,
    projectLabel: `Associated ${settings.filter_field2}`,
    quantityLabel: settings.quantity_label,
    descriptionComments: settings.description_comments_label,
  });

  const inventoryRequestFiltered = settings.wizard_mode
    ? inventoryRequest
    : filterFields(inventoryRequest, [
        "ir_start_time",
        "ir_start_date",
        "ir_location_address",
        "ir_po_number",
        "ir_designer",
        "ir_resources",
        "ir_end_time",
        "ir_end_date",
      ]);

  const inventoryItemsFiltered = settings.wizard_mode
    ? inventoryItems
    : filterFields(inventoryItems, [
        "ii_building",
        "ii_location",
        "ii_disposition",
        "ii_floor",
        "ii_idsr_number",
        "ii_po_number",
        "ii_room",
        "ii_notes",
      ]);

  const ordersFiltered = settings.wizard_mode ? orders : filterFields(orders, ["order_start_date"]);

  return {
    warehouseInfo,
    orders: ordersFiltered,
    inventoryRequest: inventoryRequestFiltered,
    shoppingCart,
    inventoryItems: inventoryItemsFiltered,
  };
}
