import orderBy from "lodash/orderBy";

export const getImageDegree = url => {
  if (!url) return 0;

  const segments = url.split("/");
  const rotateSegment = segments.find(segment => segment.startsWith("rotate="));

  if (!rotateSegment) return 0;

  const [, value] = rotateSegment.split("=");
  const [, degrees] = value.split(":");
  const rotation = parseInt(degrees, 10);

  return Number.isInteger(rotation) ? rotation : 0;
};

const degReg = /rotate=deg:\d+/g;
const defaultRotate = 0;

export const getDegree = (url, rotate) => {
  if (!url) return null;

  return url
    .split("/")
    .filter(v => v.split("=")[0] !== "rotate")
    .join("/")
    .replace("filestackcontent.com/", `filestackcontent.com/rotate=deg:${rotate}/`);
};

export const getImage = (url, type = "height", size = 40) => {
  if (!url) return null;

  if (url.search(degReg) !== -1) {
    return url.replace("filestackcontent.com/", `filestackcontent.com/resize=${type}:${size}/`);
  }

  return getDegree(url, defaultRotate).replace(
    "filestackcontent.com/",
    `filestackcontent.com/resize=${type}:${size}/`
  );
};

export const rotateImage = (url, type = "height", size = 40, rotate = "0") => {
  if (!url) return null;

  return getDegree(url, rotate).replace(
    "filestackcontent.com/",
    `filestackcontent.com/resize=${type}:${size}/`
  );
};

export const getImageSize = (url, type = "width", size = 40) => {
  if (!url) return null;

  return url.replace("filestackcontent.com/", `filestackcontent.com/resize=${type}:${size}/`);
};

export const getBackgroundImage = (url, type, size) => {
  if (!url) return null;

  return `url(${getImageSize(url, type, size)})`;
};

export function sortImages(images) {
  return orderBy(images || [], v => v.default, "desc");
}
