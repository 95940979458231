import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import Header from "../../Header";
import SearchItems from "./SearchItems";

export default function ItemLookup({ createAssetLoading, onCreateAsset, onClose }) {
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <Box bgcolor="#f4f6f8">
        <Header
          title="Item Details"
          info={
            <Box display="flex" alignItems="center">
              <PopupState variant="popover" popupId="item-select">
                {popupState => (
                  <React.Fragment>
                    <React.Fragment>
                      <ButtonBase variant="contained" {...bindTrigger(popupState)}>
                        More Options
                      </ButtonBase>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            onCreateAsset();
                          }}
                        >
                          {createAssetLoading ? (
                            <CircularProgress size={18} sx={{ mr: 1 }} />
                          ) : (
                            <AddIcon fontSize="small" sx={{ mr: 1 }} />
                          )}
                          Add New Item
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  </React.Fragment>
                )}
              </PopupState>
            </Box>
          }
        />
      </Box>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <Box display="flex" justifyContent="center">
          <Box
            mx={2}
            component="img"
            borderRadius={3}
            width="100%"
            maxWidth={300}
            minHeight="100px"
            src="/item-lookup.png"
          />
        </Box>
        <Typography fontWeight={500} align="center" fontSize={18}>
          Select the item you received from the list below
        </Typography>
        <Box mt={2}>
          <SearchItems />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
