import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const InputSkeleton = () => <Skeleton variant="rect" height={40} />;

const BigButtonSkeleton = () => (
  <Box sx={{ aspectRatio: "5/4" }}>
    <Skeleton variant="rect" width="100%" height="100%" />
  </Box>
);

export default function CaptureSkeleton() {
  return (
    <Box>
      <Card>
        <CardContent>
          <Box mt={-1} mb={1}>
            <Skeleton variant="text" width={100} height={30} />
          </Box>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item md={6} xs={12}>
              <BigButtonSkeleton />
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputSkeleton />
                </Grid>
                <Grid item xs={6}>
                  <InputSkeleton />
                </Grid>
                <Grid item xs={6}>
                  <InputSkeleton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={2}>
        <Card>
          <CardContent>
            <Box mt={-1} mb={1}>
              <Skeleton variant="text" width={100} height={30} />
            </Box>
            <Grid container alignItems="flex-start" spacing={1}>
              {Array.from({ length: 10 }).map((_, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <InputSkeleton />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
