import React from "react";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import labels from "../../../constants/displayFields";
import Input from "../../form/Input";
import BooleanSelectField from "./BooleanSelectField";

export default function MechanicalInfoFields({ fullWidth, display, disabledForm }) {
  const xs = fullWidth ? 12 : 6;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Mechanical
          </Typography>
        </Box>
      </Grid>
      {display.ase && (
        <Grid item xs={xs}>
          <BooleanSelectField name="ase" label={labels.ase} disabled={disabledForm} />
        </Grid>
      )}
      {display.exhaust && (
        <Grid item xs={xs}>
          <BooleanSelectField name="exhaust" label={labels.exhaust} disabled={disabledForm} />
        </Grid>
      )}
      {display.vac_pump_vent_mechanical && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("vac_pump_vent_mechanical")}
            label={labels.vac_pump_vent_mechanical}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.exh_snorkel_arm_mechanical && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("exh_snorkel_arm_mechanical")}
            label={labels.exh_snorkel_arm_mechanical}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.cfm && (
        <Grid item xs={xs}>
          <Input
            type="autoExpand"
            name={camelCase("cfm")}
            label={labels.cfm}
            disabled={disabledForm}
          />
        </Grid>
      )}
    </Grid>
  );
}
