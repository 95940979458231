import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import AutoExpandInput from "./AutoExpandInput";

export default function InputField({ type, ...props }) {
  if (type === "autoExpand") {
    return <AutoExpandInput {...props} />;
  }

  return (
    <FormControl fullWidth>
      <TextField type={type} {...props} />
    </FormControl>
  );
}
