import useAuth from "../../../hooks/useAuth";

export default function useHiddenFields(hideFields) {
  const { user } = useAuth();
  const settings = user.company.settings;

  const fieldsToHide = [
    !settings.inventory_tracking && [
      "warehouse_info",
      "inventory_items",
      "inventory_request",
      "shopping_cart",
      "orders",
      "reservations",
    ],
    !settings.medical_fields && ["electrical_info", "plumbing_info", "mechanical_info"],
    !settings.reservations && ["reservations"],
    !settings.receiving_wizard && ["shipments"],
  ]
    .flat()
    .filter(Boolean);

  return [...hideFields, ...fieldsToHide];
}
