import React, { useCallback, useMemo } from "react";
import { FastField } from "formik";

import InputField from "./InputField";
import { useStyles } from "../Page";

const isBlank = value => value === undefined || value === null || value === "" || value === 0;

export const parseValue = (props, value) =>
  props.type === "number"
    ? value === "" || value === null
      ? props.step
        ? 0.0
        : 0
      : props.step
      ? parseFloat(value)
      : parseInt(value)
    : value;

const Input = props => {
  const classes = useStyles();
  const inputProps = useMemo(() => ({ type: props.type, step: props.step }), [
    props.type,
    props.step,
  ]);

  const onChange = useCallback(
    setFieldValue => event => {
      const newValue = parseValue(inputProps, event.target.value);

      setFieldValue(props.name, newValue);

      if (props.onChange) {
        props.onChange(props.name, newValue);
      }
    },
    [inputProps, props.name, props.onChange]
  );

  return (
    <FastField {...props}>
      {({ field, form, meta }) => {
        const textAreaProps = props.type == "text" ? { multiline: true, rows: 3, rowsMax: 6 } : {};
        const errorMessage = meta.error || form.errors[field.name];
        const error = (meta.touched || form.submitCount > 0) && errorMessage;

        if (!meta.touched && !field.value && props.value) {
          form.setFieldValue(field.name, props.value);
        }

        return (
          <InputField
            {...field}
            size="small"
            autoComplete="off"
            value={isBlank(field.value) ? "" : field.value}
            error={!!error}
            helperText={error}
            variant="outlined"
            {...props}
            margin="none"
            {...textAreaProps}
            onChange={onChange(form.setFieldValue)}
            inputProps={{ ...inputProps, ...props.inputProps }}
          />
        );
      }}
    </FastField>
  );
};

export default React.memo(Input);
