import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ButtonGroup from "../../../form/ButtonGroup";
import Input from "../../../form/Input";
import Footer from "../Footer/Footer";
import Image from "../Image";
import Header from "../Header";
import TotalReceivingUnits from "./TotalReceivingUnits/TotalReceivingUnits";
import useMutation from "../../../../hooks/useMutation";
import { GET_SHIPMENT_QUERY } from "../../../../constants/graphql/queries";
import {
  ADD_SHIPMENT_ATTACHMENT_MUTATION,
  REMOVE_SHIPMENT_ATTACHMENT_MUTATION,
} from "../../../../constants/graphql/mutations";
import ScannerInput from "../../../../components/form/ScannerInput";

export default function Step0({ id, shipment, isSubmitting, display }) {
  const [addShipmentAttachment, { loading: adding }] = useMutation(
    ADD_SHIPMENT_ATTACHMENT_MUTATION
  );
  const [removeShipmentAttachment, { loading: removing }] = useMutation(
    REMOVE_SHIPMENT_ATTACHMENT_MUTATION
  );

  const handleUpload = files => {
    const file = files[0];
    addShipmentAttachment({
      variables: { shipmentId: id, file },
      refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id } }],
    });
  };

  const handleRemoveImage = data => {
    removeShipmentAttachment({
      ...data,
      variables: {
        shipmentId: id,
        ...data.variables,
      },
      refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id } }],
    });
  };

  return (
    <>
      <Header title="Shipment Details" />
      <Box height={3}>{(removing || adding) && <LinearProgress />}</Box>
      <Box
        p={2}
        minHeight={200}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Image
              images={shipment.attachments}
              onUpload={handleUpload}
              onRemove={handleRemoveImage}
            />
          </Grid>
          <Grid item xs={12}>
            <Box my={1} display="flex" justifyContent="center">
              <input
                type="file"
                accept="image/*"
                id="item-button"
                capture="environment"
                style={{ display: "none" }}
                onChange={e => handleUpload(e.target.files)}
              />
              <label htmlFor="item-button" style={{ width: 400, margin: 0 }}>
                <Button
                  size="large"
                  fullwidth
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component="span"
                >
                  <AddAPhotoIcon />
                </Button>
              </label>
            </Box>
          </Grid>
          {display.shipment_id && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                value={shipment.shipmentId}
                label="Shipment ID"
              />
            </Grid>
          )}
          {display.carrier_name && (
            <Grid item xs={12} sm={6}>
              <Input name="carrierName" label="Carrier Name" />
            </Grid>
          )}
          {display.vendor_name && (
            <Grid item xs={12} sm={6}>
              <Input name="vendorName" label="Vendor Name" />
            </Grid>
          )}
          {display.order_number && (
            <Grid item xs={12} sm={6}>
              <ScannerInput name="orderNumber" label="Order Number" />
            </Grid>
          )}
          {display.customer_po_number && (
            <Grid item xs={12} sm={6}>
              <ScannerInput name="customerPoNumber" label="Customer PO Number" />
            </Grid>
          )}
          {display.bol && (
            <Grid item xs={12} sm={6}>
              <ScannerInput name="bol" label="BOL Number" />
            </Grid>
          )}
          {display.tracking_number && (
            <Grid item xs={12} sm={6}>
              <ScannerInput name="trackingNumber" label="Tracking Number" />
            </Grid>
          )}
          {display.total_weight && (
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Input type="number" name="totalWeight" label="Total Weight" />
                </Grid>
                <Grid item xs={6}>
                  <Box mt="2px">
                    <ButtonGroup
                      name="weightUnits"
                      options={[
                        ["Lbs", "Lbs"],
                        ["Kgs", "Kgs"],
                      ]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {display.total_receiving_units && (
            <Grid item xs={12} sm={6}>
              <TotalReceivingUnits />
            </Grid>
          )}
          {display.shipment_notes && (
            <Grid item xs={12}>
              <Input name="notes" label="Notes" />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Footer step={0} shipment={shipment}>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={22} />}
            type="submit"
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            Next
          </Button>
        </Footer>
      </Box>
    </>
  );
}
