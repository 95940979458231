import React from "react";

import { AssetsCollectionProvider } from "../../contexts/assetsCollection";
import { useAssetsFilterVariables } from "../../hooks/capture/useAssetsFilterVariables";
import { useCapture } from "../../contexts/capture";

export default function CollectionProvider({ type, hasImportedAssets, children }) {
  const { order } = useCapture();
  const variables = useAssetsFilterVariables(order, hasImportedAssets);

  return (
    <AssetsCollectionProvider type={type} variables={variables}>
      {children}
    </AssetsCollectionProvider>
  );
}
