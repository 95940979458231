import React, { useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FixedSizeList as SizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { useAssets } from "../../../contexts/assets";
import ImageModal from "../../images/ImageModal";
import SortAssets from "../SortAssets";
import AssetItem from "./AssetItem";
import useInfinityScroll from "../../../hooks/capture/useInfinityScroll";
import AssetItemSkeleton from "./AssetItemSkeleton";
import { useCapture } from "../../../contexts/capture";

export const listRef = React.createRef();

export default function AssetList({
  height,
  selectedIds,
  unSelectBulk,
  bulkProps,
  AssetItemComponent = AssetItem,
  onSelectAsset,
}) {
  const { order, setOrder } = useCapture();
  const {
    loading,
    loadingMutation,
    collection,
    onLoadMore,
    totalCount,
    limit,
    currentAsset,
    setCurrentAsset,
    selectedImage,
    onChangeImage,
    removeAsset,
    onSelectImage,
    removeImage,
    updateImage,
    bulkCreateImages,
  } = useAssets();

  const [modalAssetId, setModalAssetId] = useState(null);
  const openedAsset = modalAssetId && collection.find(asset => asset.id === modalAssetId);

  const handleChangeCurrentAsset = value => {
    setCurrentAsset(value);
    onSelectAsset && onSelectAsset(value);
  };

  const { itemCount, loadMoreItems, isItemLoaded } = useInfinityScroll({
    entities: collection,
    totalCount,
    rowsCount: limit,
    onLoadMore,
    loading: loading || loadingMutation,
  });

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: openedAsset?.id },
      onSuccess,
      onFailure,
    });
  };

  return (
    <>
      {!loading && collection.length === 0 && (
        <Box textAlign="center" width="100%">
          <Typography variant="h2" color="textSecondary">
            Empty
          </Typography>
        </Box>
      )}
      <SortAssets bulkProps={bulkProps} order={order} setOrder={setOrder} />
      <Box display="flex" flexDirection="column" style={{ height, overflowY: "auto" }}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <SizeList
              className={clsx({ "no-scrollbars": loadingMutation })}
              ref={ref}
              height={height}
              onItemsRendered={onItemsRendered}
              itemData={collection}
              itemCount={itemCount}
              itemSize={58}
              width="100%"
            >
              {({ style, data, index }) => (
                <div style={style} data-id="assets-list">
                  {data[index] ? (
                    <AssetItemComponent
                      asset={data[index]}
                      currentAsset={currentAsset}
                      setCurrentAsset={handleChangeCurrentAsset}
                      removeImage={removeImage}
                      selectedImage={selectedImage}
                      onChangeImage={onChangeImage}
                      onOpenImageModal={setModalAssetId}
                      onRemove={removeAsset}
                      onSelectImage={onSelectImage}
                      selectedIds={selectedIds}
                      unSelectBulk={unSelectBulk}
                      bulkProps={bulkProps}
                    />
                  ) : (
                    <AssetItemSkeleton checkbox />
                  )}
                </div>
              )}
            </SizeList>
          )}
        </InfiniteLoader>
      </Box>
      {openedAsset && (
        <ImageModal
          open={Boolean(openedAsset?.id)}
          selectedId={selectedImage}
          asset={openedAsset}
          images={openedAsset.images}
          onClose={() => setModalAssetId(null)}
          onRemove={removeImage}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
    </>
  );
}
