import React from "react";
import { useParams } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import Box from "@material-ui/core/Box";
import camelCase from "lodash/camelCase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RefetchIcon from "@material-ui/icons/Cached";

import useAuth from "../../hooks/useAuth";
import useMutation from "../../hooks/useMutation";
import { UPDATE_USER_MUTATION } from "../../constants/graphql";
import Views from "./Views";
import Fields from "./Fields";
import useCaptureFilterFields from "../../hooks/capture/useCaptureFilterFields";
import useUpdateProjectSettings from "../../hooks/useUpdateProjectSettings";
import Order from "../../components/TablePro/Order";

export default function TableSettings({ type, refetch, fields, onChange }) {
  const { projectId } = useParams();
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const { user, setUser } = useAuth();
  const initialValues = useCaptureFilterFields();
  const projectSpecificFields = user.company.settings.project_specific_fields;
  const receivingWizard = user.company.settings.receiving_wizard;
  const scrubberValues = Object.fromEntries(
    fields.map(field => [snakeCase(field.id), field.visible])
  );
  const scrubberInitialValues =
    projectSpecificFields && projectId
      ? { power: initialValues.power, ...scrubberValues }
      : scrubberValues;

  const [updateProjectSettings] = useUpdateProjectSettings();

  const onFieldsChangeScrubber = values => {
    if (projectSpecificFields && projectId) {
      const fieldSettings = { ...values };
      if (values.photo_carousel !== initialValues.photo_carousel) {
        fieldSettings.photo = values.photo_carousel;
      }

      if (values.barcode !== initialValues.barcode) {
        fieldSettings.barcode_number = values.barcode;
      }

      return updateProjectSettings({
        variables: { projectId, fieldSettings },
      });
    } else {
      const ids = Object.entries(values)
        .filter(([, value]) => value)
        .map(([name]) => camelCase(name));

      const newFields = fields.map(field => ({
        ...field,
        visible: ids.includes(field.id),
      }));

      return onChange({ fields: newFields });
    }
  };

  const onFieldsChangeCapture = captureTableSettings => {
    if (projectSpecificFields && projectId) {
      const fieldSettings = { ...captureTableSettings };
      if (captureTableSettings.photo !== scrubberInitialValues.photo) {
        fieldSettings.photo_carousel = captureTableSettings.photo;
      }
      if (captureTableSettings.barcode_number !== scrubberInitialValues.barcode_number) {
        fieldSettings.barcode = captureTableSettings.barcode_number;
      }
      return updateProjectSettings({
        variables: { projectId, fieldSettings },
      });
    } else {
      setUser({ ...user, captureTableSettings });
      return updateUser({
        variables: { captureTableSettings },
        onSuccess: data => {
          setUser({ ...user, captureTableSettings });
        },
      });
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ minHeight: 46 }}>
      {type === "capture" && (
        <Fields onSubmit={onFieldsChangeCapture} initialValues={initialValues} />
      )}
      {type === "scrubber" && (
        <>
          <Fields
            type="scrubber"
            onSubmit={onFieldsChangeScrubber}
            initialValues={scrubberInitialValues}
            reorderFields={fields}
            onChangeOrder={onChange}
          />
          {receivingWizard && (
            <Order fields={fields} onChange={onChange} />
          )}
        </>
      )}
      <Box mr={1} />
      <Views />
      <Box mr={1} />
      <Button style={{ textTransform: "none" }} size="small" onClick={refetch}>
        <RefetchIcon color="action" />
        <Box mr={1} />
        <Typography fontWeight={600} color="textSecondary" variant="body2">
          Refresh
        </Typography>
      </Button>
    </Box>
  );
}
