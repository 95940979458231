import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@mui/material/TextField";

import labels from "../../constants/displayFields";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import RackLocationsCapture from "../../views/capture/RackLocations/RackLocationsCapture";
import useAuth from "../../hooks/useAuth";
import RackLocationsScrubber from "../../views/capture/RackLocations/RackLocationsScrubber";
import ScrubberAvailableQuantityInput from "./ScrubberAvailableQuantityInput";
import AvailableQuantityInput from "./AvailableQuantityInput";
import { palletTypeOptions } from "../../constants/receiving-wizard";

export default function FieldsStorage({
  onRestockInventory,
  fullWidth,
  display,
  disabledForm,
  type,
  asset,
}) {
  const { user } = useAuth();
  const md = fullWidth ? 12 : 4;
  const isItem = type === "item";

  const displaySection =
    display.available_quantity ||
    display.reserved_quantity ||
    display.rack_locations ||
    display.quantity_ordered ||
    display.po_number ||
    display.warehouse_quantity ||
    display.minimum_quantity;

  return displaySection ? (
    <Card style={{ height: "100%", overflow: "visible" }}>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Storage
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {display.available_quantity && (
            <Grid item xs={12} sm={md}>
              {type === "scrubber" ? (
                <ScrubberAvailableQuantityInput
                  display={display}
                  user={user}
                  asset={asset}
                  disabledForm={disabledForm}
                />
              ) : (
                <AvailableQuantityInput
                  type={type}
                  display={display}
                  user={user}
                  asset={asset}
                  disabledForm={disabledForm}
                  onRestockInventory={onRestockInventory}
                />
              )}
            </Grid>
          )}
          {!isItem && display.reserved_quantity && (
            <Grid item xs={12} sm={md}>
              <TextField
                disabled
                fullWidth
                value={asset.reservedQuantity}
                size="small"
                label={labels.reserved_quantity}
              />
            </Grid>
          )}
          {display.rack_locations && (
            <Grid item xs={12} md={md}>
              {type === "scrubber" ? (
                <RackLocationsScrubber type={type} entity={asset} disabled={disabledForm} />
              ) : (
                <RackLocationsCapture type={type} entity={asset} disabled={disabledForm} />
              )}
            </Grid>
          )}
          {display.quantity_ordered && (
            <Grid item xs={12} sm={md}>
              <Input
                type="number"
                name="quantityOrdered"
                label={labels.quantity_ordered}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {!isItem && display.po_number && (
            <Grid item xs={12} md={md}>
              <Input
                type="autoExpand"
                name="poNumber"
                label={labels.ii_po_number}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.pallet_type && (
            <Grid item xs={12} md={md}>
              <Select
                size="small"
                name="palletType"
                label={labels.pallet_type}
                disabled={disabledForm}
                options={palletTypeOptions}
              />
            </Grid>
          )}
          {!isItem && display.minimum_quantity && (
            <Grid item xs={12} md={md}>
              <Input
                type="number"
                name="minimumQuantity"
                label={labels.minimum_quantity}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {!isItem && display.warehouse_quantity && (
            <Grid item xs={12} md={md}>
              <TextField
                disabled
                fullWidth
                value={asset.warehouseQuantity}
                size="small"
                label={labels.warehouse_quantity}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : null;
}
