import React, { createRef } from "react";
import { Formik } from "formik";
import { useParams, useLocation } from "react-router-dom";

import { fetchAssetValues } from "../../utils/asset";
import { useAssets } from "../../contexts/assets";
import FormPage from "./FormPage";

export const formRef = createRef();

export default function Form() {
  const { currentAsset } = useAssets();
  const { clientId } = useParams();
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/");
  const isClientsRoute = pathSegments[1] === "clients";
  const displayClient = isClientsRoute && clientId === "null";
  const displayProject = isClientsRoute;

  return (
    <Formik
      key={currentAsset?.id}
      initialValues={fetchAssetValues(currentAsset)}
      onSubmit={() => {}}
      enableReinitialize
      innerRef={formRef}
    >
      <FormPage type="capture" displayClient={displayClient} displayProject={displayProject} />
    </Formik>
  );
}
