import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { fade, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import { useDeleteEffect, useOnAlphaNumEffect, useOnEnterEffect } from "../../../utils/keyEffects";
import { callerField } from "../../../utils/caller";
import { inFillDownAreaCell as inFillDownArea } from "../extensions/useFillDownCells";
import InputField, { parseValue } from "../fields/InputField";
import TableCell from "../TableCell";
import DefaultViewComponent from "./DefaultViewComponent";
import { editableFieldsFilter, sliceItems } from "../utils";

const useStyles = makeStyles(theme => ({
  focusInput: {
    position: "absolute",
    resize: "none",
    opacity: 0,
    width: 1,
    height: 1,
    right: 0,
    bottom: 0,
  },
  cellContainer: {
    border: "2px solid transparent",
    "&.editable": {
      borderColor: theme.palette.success.main,
    },

    "&.selected": {
      borderColor: theme.palette.success.main,
    },

    "&.highlightBorder": {
      borderColor: "transparent",
      backgroundColor: fade(theme.palette.success.main, 0.08),
    },

    "&.rootHighlight": {
      border: `2px solid ${theme.palette.success.main} !important`,
    },

    "&.dashBorder": {
      borderColor: "transparent",
      borderStyle: "dashed",
    },

    "&.topBorder": {
      borderTopColor: theme.palette.success.main,
    },

    "&.bottomBorder": {
      borderBottomColor: theme.palette.success.main,
    },

    "&.leftBorder": {
      borderLeftColor: theme.palette.success.main,
    },

    "&.rightBorder": {
      borderRightColor: theme.palette.success.main,
    },
  },
  inputField: {
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "inherit",
  },
  highlightElement: {
    position: "absolute",
    bottom: -5,
    right: -5,
    width: 10,
    cursor: "ew-resize",
    backgroundColor: theme.palette.success.main,
    zIndex: 1,
    padding: 6,
    display: "block",
  },
}));

export const isEqualCell = (prev, next) => {
  const field = next.field;
  const fetchValue = entity => callerField(entity, field);
  const coords = [next.rowIndex, next.columnIndex];

  // Table field
  if (!isEqual(Object.values(prev.field).sort(), Object.values(next.field).sort())) {
    return false;
  }

  // Entity attributes
  if (prev.entity.id !== next.entity.id) {
    return false;
  }

  if (!isEqual(fetchValue(prev.entity), fetchValue(next.entity))) {
    return false;
  }

  // useEditableCells

  if (isEqual(coords, prev.rootCoords) !== isEqual(coords, next.rootCoords)) {
    return false;
  }

  if (isEqual(coords, prev.editCoords) !== isEqual(coords, next.editCoords)) {
    return false;
  }

  if (
    inFillDownArea(coords, prev.rootCoords, prev.endCoords) !==
    inFillDownArea(coords, next.rootCoords, next.endCoords)
  ) {
    return false;
  }

  if (
    inFillDownArea(coords, prev.copyDiapason[0], prev.copyDiapason[1]) !==
    inFillDownArea(coords, next.copyDiapason[0], next.copyDiapason[1])
  ) {
    return false;
  }

  if (prev.fillDownMode !== next.fillDownMode) {
    return false;
  }

  return true;
};

export const TableCellStub = ({ className, field, fields }) => (
  <TableCell fields={fields} field={field} className={className}>
    <Skeleton variant="rect" height="20" width="50%" />
  </TableCell>
);

const fetchBorderPosition = (coords, startCoords, endCoords) => {
  const reverseRow = startCoords[0] > endCoords[0];
  const startRow = reverseRow ? endCoords[0] : startCoords[0];
  const endRow = reverseRow ? startCoords[0] : endCoords[0];

  const reverseColumn = startCoords[1] > endCoords[1];
  const startColumn = reverseColumn ? endCoords[1] : startCoords[1];
  const endColumn = reverseColumn ? startCoords[1] : endCoords[1];

  return {
    top: coords[0] === startRow,
    bottom: coords[0] === endRow,
    left: coords[1] === startColumn,
    right: coords[1] === endColumn,
  };
};

const getList = (root, end) => {
  return end > root
    ? Array(end - root + 1)
        .fill()
        .map((v, i) => root + i)
    : end < root
    ? Array(root - end + 1)
        .fill()
        .map((v, i) => end + i)
    : [root];
};

const TableCellComponent = ({
  size,
  fields,
  field,
  entity,
  rowIndex,
  columnIndex,
  onChange,

  fillDownElementProps,
  fillDownCellProps,

  editCoords,
  rootCoords,
  endCoords,
  copyDiapason,
  setEditCoords,
  setEndCoords,
  setSelectedCoords,
  fillDownMode,

  nextRow,
  prevRow,
  nextCell,
  prevCell,
  getSelectedEntities,
}) => {
  const coords = [rowIndex, columnIndex];
  const selected = isEqual(coords, rootCoords);
  const editable = isEqual(coords, editCoords);
  const changingInSelectedMode = selected && !editable && !field.unEditable;
  const noFillDown = isEmpty(endCoords);

  const inDiapason = inFillDownArea(coords, copyDiapason[0], copyDiapason[1]);
  const diapasonBorders = inDiapason
    ? fetchBorderPosition(coords, copyDiapason[0], copyDiapason[1])
    : {};

  const inHighlight = inFillDownArea(coords, rootCoords, endCoords);
  const highlightBorders =
    inHighlight && !fillDownMode ? fetchBorderPosition(coords, rootCoords, endCoords) : {};

  const classes = useStyles();
  const cellRef = useRef(null);
  const inputRef = useRef(null);
  const inputAutoRef = useRef(null);
  const openEditor = !field.unEditable && editable;
  const InputComponent = field.input || InputField;
  const ViewComponent = field.component || DefaultViewComponent;
  const isInputField = InputComponent === InputField;
  const defaultValue = callerField(entity, field);
  const [value, setValue] = useState(defaultValue);

  const fillDownProps = field.disableFillDown ? {} : fillDownCellProps(rowIndex, columnIndex);

  const handleSelect = event => {
    if (!openEditor) {
      setEndCoords([]); 
      setEditCoords([]);
      setSelectedCoords([rowIndex, columnIndex]);
      fillDownProps.onMouseDown && fillDownProps.onMouseDown(event);
    }
  };

  const handleEditor = () => {
    !field.unEditable && setEditCoords([rowIndex, columnIndex]);
  };

  const onRevert = () => {
    if (openEditor) {
      setValue(defaultValue);
      setEditCoords([]);
    }
  };

  const handleSubmit = (newValue = value, options = {}) => {
    if (newValue !== defaultValue) {
      setValue(newValue);
      onChange(entity.id, { [field.id]: newValue }, options);
    }

    setEditCoords([]);
  };

  const handleDelete = () => {
    if (defaultValue === "" || defaultValue === 0 || defaultValue === null) {
      return;
    }
    const isMulti = endCoords.length > 0;
    if (isMulti) {
      const [rootRow, rootColumn] = rootCoords;
      const [endRow, endColumn] = endCoords;
      const rows = getList(rootRow, endRow);
      const columns = rootColumn > endColumn ? [endColumn, rootColumn] : [rootColumn, endColumn];
      const selectedFields = sliceItems(fields, ...columns).filter(editableFieldsFilter);
      const selectedEntities = getSelectedEntities(rows);
      selectedEntities.forEach(item => {
        const newValue = field.initialValue ? field.initialValue : parseValue(field.inputType, "");
        setValue(newValue);
        onChange(
          item.id,
          Object.fromEntries(
            selectedFields.map(v => [
              v.id,
              v.initialValue ? v.initialValue : parseValue(v.inputType, ""),
            ])
          )
        );
      });
    } else {
      const newValue = field.initialValue ? field.initialValue : parseValue(field.inputType, "");
      setValue(newValue);
      onChange(entity.id, { [field.id]: newValue });
    }
  };

  const handleUpdateInSelected = keyCode => {
    if (!selected || openEditor || field.unEditable) return;

    if (isInputField || field.type === "autocomplete") {
      setValue(parseValue(field.inputType, keyCode));
    }

    handleEditor();
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useDeleteEffect(cellRef, changingInSelectedMode ? handleDelete : null);

  useOnAlphaNumEffect(
    cellRef,
    changingInSelectedMode && noFillDown ? handleUpdateInSelected : null
  );

  useOnEnterEffect(cellRef, changingInSelectedMode && noFillDown ? handleEditor : null, {
    // This fix was added for issue when enter effect is triggered on render SelectField
    condition: event => !event.target.getAttribute("data-select-id"),
  });

  const cellContentProps = {
    ...fillDownProps,
    onDoubleClick: handleEditor,
    onMouseDown: handleSelect,
  };

  const componentProps = {
    id: field.id,
    size,
    entity: entity,
    rowIndex: rowIndex,
    columnIndex: columnIndex,
    field: field,
    innerRef: inputRef,
    className: classes.inputField,
    value: value,
    onSubmit: handleSubmit,
    onChange: onChange,
    onRevert: onRevert,
    setValue: setValue,
    onClose: () => setEditCoords([]),
    moveToNextColumn: () => {
      setEndCoords([]);
      setEditCoords([]);
      setSelectedCoords([rowIndex + 1, columnIndex]);
    },
    selected,
    nextRow: () => nextRow(rowIndex, columnIndex),
    prevRow: () => prevRow(rowIndex, columnIndex),
    nextColumn: () => nextCell(fields, rowIndex, columnIndex),
    prevColumn: () => prevCell(fields, rowIndex, columnIndex),
  };

  const fillDownElement = !field.disableFillDown &&
    !field.unEditable &&
    selected &&
    !openEditor && (
      <div
        {...fillDownElementProps(rowIndex, columnIndex)}
        className={clsx(classes.highlightElement)}
      />
    );

  const classesClsx = {
    editable: openEditor,
    ...(field.system
      ? {}
      : {
          selected: !inDiapason && selected,
          rootHighlight: !inDiapason && selected && fillDownMode,
          highlightBorder: !inDiapason && inHighlight && !isEqual(endCoords, rootCoords),
          dashBorder: inDiapason,
          topBorder: highlightBorders.top || diapasonBorders.top,
          bottomBorder: highlightBorders.bottom || diapasonBorders.bottom,
          leftBorder: highlightBorders.left || diapasonBorders.left,
          rightBorder: highlightBorders.right || diapasonBorders.right,
        }),
  };

  return (
    <TableCell
      data-field={`${field.id}-${entity.id}`}
      innerRef={cellRef}
      fields={fields}
      field={field}
      nestedElement={fillDownElement}
      contentProps={cellContentProps}
      className={clsx(classes.cellContainer, classesClsx, 'pro-table-cell')}
    >
      {openEditor && <InputComponent {...componentProps} />}

      {!openEditor && <ViewComponent {...componentProps} />}

      {selected && !openEditor && <input ref={inputAutoRef} className={classes.focusInput} />}
    </TableCell>
  );
};

export default TableCellComponent;
