import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useAuth from "src/hooks/useAuth";

export default function SelectProjectAttentionModal({ open, onClose }) {
  const { user } = useAuth();
  const { settings } = user.company;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontSize={24} fontWeight={500} color="textPrimary" align="center">
          Attention
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={16} fontWeight={400} color="textPrimary" align="center">
          Please select a {settings.filter_field2} to perform the selected action
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
