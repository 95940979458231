import React, { useCallback } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import useMutation from "src/hooks/useMutation";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AssetItemImage from "../../../aseet-list/AssetItemImage";
import AssetItemSkeleton from "../../../aseet-list/AssetItemSkeleton";
import AssetItemDescription from "../../../aseet-list/AssetItemDescription";
import useQueryParams from "src/hooks/useQueryParams";
import { ADD_SHIPMENT_ITEMS_MUTATION } from "src/constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "src/constants/graphql/queries";

const styles = {
  button: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: 0,
    boxSizing: "border-box",
    borderBottom: "1px solid #DDDBDA",
    height: 58,
    textAlign: "left",
    width: "100%",
    overflow: "hidden",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#f4f6f8",
    },
  },
  arrow: {
    fontSize: 18,
    color: "action.active",
    ml: 1,
  },
};

function AssetListItem({ order, asset, style }) {
  const history = useHistory();
  const params = useQueryParams();
  const shipmentId = params.get("id");
  const [addShipmentItem] = useMutation(ADD_SHIPMENT_ITEMS_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
  });

  const handleAddAsset = useCallback(async () => {
    const response = await addShipmentItem({
      variables: {
        assetId: asset.id,
        shipmentId,
        quantity: 0,
      },
    });

    const shipmentItemId = response.data.addShipmentItems.shipmentItem.id;
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.delete("itemLookup");
    params.set("assetId", asset.id);
    params.set("shipmentItemId", shipmentItemId);
    params.set("addShipmentItem", "true");
    history.push({ pathname, search: params.toString() });
  }, [asset, history]);

  if (!asset) {
    return (
      <div style={style} data-id="assets-list">
        <AssetItemSkeleton />
      </div>
    );
  }

  return (
    <div style={style} data-id="assets-list">
      <ButtonBase fullWidth variant="outlined" sx={styles.button} onClick={handleAddAsset}>
        <AssetItemImage images={asset.images} />
        <AssetItemDescription order={order} asset={asset} />
        <ArrowForwardIosIcon sx={styles.arrow} />
      </ButtonBase>
    </div>
  );
}

export default AssetListItem;
