import { useState } from "react";
import useQueryParams from "../useQuery";

export default function useAssetsFilter(variables, type) {
  const queryParams = useQueryParams();
  const hasMissingItems = queryParams.get("missingItems") === "true";
  const [search, setSearch] = useState(variables.search);
  const [limit, setLimit] = useState(variables.limit || 40);

  const filter = {
    ...variables,
    search: search,
    limit,
    hasMissingItems,
    page: variables.page || 1,
    type,
  };

  return { filter, search, setSearch, limit, setLimit };
} 