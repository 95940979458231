import React from "react";
import moment from "moment";

const DATE_FORMAT = "MM/DD/yyyy hh:mm A";
const serverFormat = "YYYY-MM-DDTHH:mm:ss Z";

const formatDateTime = dateTime => {
  return moment(dateTime, serverFormat).format(DATE_FORMAT);
};

export default ({ value }) => <span>{formatDateTime(value)}</span>;
