import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";

import ConfirmDeleteModal from "../../images/ConfirmDeleteModal";
import { useCapture } from "../../../contexts/capture";
import { getAssetItemImage } from "./AssetItemImage";
import AssetItemImage from "./AssetItemImage";
import AssetItemDescription from "./AssetItemDescription";

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: "flex",
    justifyContent: "flex-start",
    border: 0,
    boxSizing: "border-box",
    borderBottom: "1px solid #DDDBDA",
    background: props.selected ? "#f4f6f8" : "white",
    height: 58,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    maxWidth: "100%",
    overflow: "hidden",
    borderRadius: 0,
    textTransform: "none",
  }),
}));

export default function AssetItem({
  currentAsset,
  setCurrentAsset,
  onRemove,
  onOpenImageModal,
  asset,
  onSelectImage,
  bulkProps,
}) {
  const { order } = useCapture();
  const [deleteAsset, setDeleteAsset] = useState(false);
  const selected = currentAsset?.id === asset.id;
  const classes = useStyles({ selected });
  const Checkbox = bulkProps.component;

  const handleDelete = () =>
    onRemove({
      variables: {
        id: asset.id,
        projectId: asset.projectId,
      },
    });

  const handleToggleConfirmDeleteModal = () => setDeleteAsset(!deleteAsset);

  const image = getAssetItemImage(asset?.images);

  return (
    <>
      <Box>
        <Button
          id={`assetItem-${asset.id}`}
          fullWidth
          className={classes.root}
          variant="outlined"
          onClick={() => setCurrentAsset(asset)}
        >
          <Checkbox entity={asset} />
          <AssetItemImage
            ml={2}
            images={asset.images}
            onClick={e => {
              e.stopPropagation();
              setCurrentAsset(asset);
              onSelectImage(image.id);
              onOpenImageModal(asset.id);
            }}
          />
          <AssetItemDescription asset={asset} order={order} />
        </Button>
      </Box>
      {deleteAsset && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete asset?"
          onClose={handleToggleConfirmDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
