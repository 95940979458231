import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

const MIN_HEIGHT = 19;
const MAX_HEIGHT = 114;

const useStyles = makeStyles(theme => ({
  expanded: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    zIndex: 1300,
  },
}));

export default function InputField(props) {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const expandedTextareaRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const textarea = expandedTextareaRef.current;
    if (!textarea) return;

    const adjustHeight = () => {
      textarea.style.height = `${MIN_HEIGHT}px`;
      if (isFocused) {
        textarea.style.height = "auto";
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${Math.min(scrollHeight, MAX_HEIGHT)}px`;
      }
    };

    adjustHeight();
  }, [props.value, isFocused]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box position="relative" minHeight={40}>
      <FormControl
        fullWidth
        className={isFocused ? classes.expanded : undefined}
        ref={containerRef}
      >
        <TextField
          {...props}
          multiline={true}
          rows={1}
          inputRef={expandedTextareaRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </FormControl>
    </Box>
  );
}
