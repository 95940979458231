import React, { useEffect, useRef, useState } from "react";
import {
  LinearProgress,
  makeStyles,
  Checkbox,
  IconButton,
  Grid,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Close from "@material-ui/icons/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { getDegree, getImageDegree, rotateImage } from "../../utils/images";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import UploadFileButton from "../UploadFileButton";
import { formatDateTime } from "../../utils/dates";

const deg = { 0: 0, 90: 90, 180: 180, 270: 270 };

const useStyles = makeStyles(theme => ({
  name: {
    marginTop: theme.spacing(1),
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
  },
  avatar: {
    height: 100,
    width: 100,
  },
  content: {
    padding: 0,
  },
  dialog: {
    maxWidth: "80vw",
  },
  dialogContent: {
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
  menuLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  iconText: {
    fontSize: "16px",
  },
  iconSize: {
    fontSize: "24px",
  },
}));

const DisableTransitionComponent = ({ children }) => children;

const ImageModal = ({
  open,
  edit = true,
  images,
  asset,
  closeOnSave = false,
  selectedId: defaultId,
  onClose,
  onRemove,
  onUpdate,
  onCreate,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const [selectedId, setSelectedId] = useState(defaultId);
  const [imageHeight, setImageHeight] = useState("auto");
  const imageRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rotateAnchorEl, setRotateAnchorEl] = useState(null);

  const currentImage = images.find(image => selectedId === image.id) || images[0];
  const currentImageIndex = images.indexOf(currentImage);
  const InitialRotation = getImageDegree(currentImage?.url);
  const [defaultImage, setDefaultImage] = useState(currentImage?.default);
  const [rotate, setRotate] = useState(InitialRotation);

  const changed = InitialRotation !== rotate || currentImage?.default !== defaultImage;

  const prevImage = images[currentImageIndex - 1];
  const nextImage = images[currentImageIndex + 1];

  const handleToggleModal = () => setDeleteImage(value => !value);

  const handleRotate = value => {
    let newRotate = 0;
    if (rotate + value < 0) {
      newRotate = 360 + rotate + value;
    } else if (rotate + value >= 360) {
      newRotate = 0;
    } else {
      newRotate = rotate + value;
    }

    setRotate(newRotate);
  };

  useEffect(() => {
    if (currentImage?.id) {
      setDefaultImage(currentImage?.default);
      setRotate(getImageDegree(currentImage?.url));
    }
  }, [currentImage?.id, currentImage?.default]);

  useEffect(() => {
    setSelectedId(defaultId);
  }, [defaultId]);

  useEffect(() => {
    if (imageRef.current) {
      const imgHeight = imageRef.current.clientHeight;
      setImageHeight(imgHeight > window.innerHeight ? window.innerHeight : "auto");
    }
  }, [currentImage?.url, rotate]);

  const handleRemove = () => {
    setLoading(true);
    onRemove({
      variables: { id: currentImage?.id },
      onSuccess: () => {
        if (images.length <= 1) {
          onClose();
        }
        setLoading(false);
        setSelectedId(prevImage?.id || nextImage?.id);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  const handleSave = () => {
    setLoading(true);

    onUpdate({
      variables: {
        id: currentImage?.id,
        url: getDegree(currentImage?.url, rotate),
        default: defaultImage,
      },
      onSuccess: () => {
        setLoading(false);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  const handleUpload = files => {
    setUploading(true);

    onCreate({
      variables: { images: files },
      onSuccess: () => {
        setUploading(false);
        closeOnSave && onClose();
      },
      onFailure: () => {
        setUploading(false);
      },
    });
  };

  const handleClose = () => {
    if (loading || uploading) return;
    onClose();
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRotateMenuOpen = event => {
    setRotateAnchorEl(event.currentTarget);
  };

  const handleRotateMenuClose = () => {
    setRotateAnchorEl(null);
  };

  const handleDownloadImage = () => {
    window.open(currentImage?.url, "_blank");
    handleMenuClose();
  };

  const imgUrl = rotateImage(currentImage?.url, "width", 1024, rotate);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={DisableTransitionComponent}
        classes={{ paper: classes.dialog }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: { transition: "none !important" },
          },
        }}
      >
        <Grid container justify="space-between" wrap="nowrap">
          <Grid />
          <Grid item style={{ padding: "8px" }}>
            {onClose && (
              <IconButton disabled={loading || uploading} onClick={onClose}>
                <Close titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <DialogContent classes={{ root: classes.dialogContent }} style={{ maxHeight: imageHeight }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                mb={2}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{
                  flexDirection: "column",
                  md: { flexDirection: "row" },
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Captured On: {formatDateTime(currentImage?.createdAt)}
                </Typography>
                {edit && (
                  <Box className={classes.buttonGroup}>
                    <Box
                      className={classes.menuLabel}
                      onClick={handleRotateMenuOpen}
                      display="flex"
                      alignItems="center"
                    >
                      <RotateLeftOutlinedIcon className={classes.iconSize} />
                      <Typography className={classes.iconText} style={{ marginLeft: 10 }}>
                        Rotate
                      </Typography>
                    </Box>
                    <Menu
                      anchorEl={rotateAnchorEl}
                      keepMounted
                      open={Boolean(rotateAnchorEl)}
                      onClose={handleRotateMenuClose}
                    >
                      <MenuItem onClick={() => handleRotate(-90)}>Rotate Left</MenuItem>
                      <MenuItem onClick={() => handleRotate(90)}>Rotate Right</MenuItem>
                    </Menu>
                    {/* More Options Icon with Dropdown */}
                    <Box
                      className={classes.menuLabel}
                      onClick={handleMenuOpen}
                      display="flex"
                      alignItems="center"
                    >
                      <MoreVertIcon className={classes.iconSize} />
                      <Typography className={classes.iconText} style={{ marginLeft: 10 }}>
                        More
                      </Typography>
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem>
                        <Checkbox
                          checked={defaultImage}
                          onChange={event => setDefaultImage(Boolean(event.target.checked))}
                          color="primary"
                        />
                        <Typography variant="body2">Set As Default</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
                    </Menu>
                  </Box>
                )}
              </Box>
              <Box display="flex">
                <Box clone display="flex" flexGrow={1} maxWidth={40}>
                  <Button
                    disabled={loading || !prevImage}
                    onClick={() => setSelectedId(prevImage?.id)}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                </Box>

                {currentImage && (
                  <Box display="flex" flexGrow={1} justifyContent="center" borderRadius={4} mb={3}>
                    <InnerImageZoom src={imgUrl} zoomSrc={imgUrl} zoomScale={2} />
                  </Box>
                )}

                <Box clone display="flex" flexGrow={1} maxWidth={40}>
                  <Button
                    disabled={loading || !nextImage}
                    onClick={() => setSelectedId(nextImage?.id)}
                  >
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {uploading && <LinearProgress className="mt-2" />}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container>
            <Grid item xs={12} container justify="space-between" spacing={2}>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  disabled={loading || images.length === 0}
                  data-id="delete-image"
                  onClick={handleToggleModal}
                  variant="outlined"
                  className={classes.danger}
                >
                  Delete
                </Button>
              </Grid>

              <Grid item xs={3}>
                <UploadFileButton
                  fullWidth
                  multiple
                  name="imageModalUpload"
                  disabled={loading || uploading}
                  onUpload={handleUpload}
                  variant="outlined"
                  color="primary"
                  accept="image/*"
                >
                  Upload
                </UploadFileButton>
              </Grid>

              <Grid item xs={3}>
                <Button
                  fullWidth
                  disabled={loading || !changed}
                  data-id="save-image"
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {deleteImage && (
        <ConfirmDeleteModal
          title="Delete Image"
          descr="Are you sure you want to delete image?"
          onClose={handleToggleModal}
          onDelete={handleRemove}
        />
      )}
    </>
  );
};

export default ImageModal;
