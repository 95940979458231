import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button from "@mui/material/Button";
import useQueryParams from "src/hooks/useQueryParams";

import ImageModal from "../../../components/images/ImageModal";
import useNotification from "../../../hooks/notification";
import { useAssets } from "../../../contexts/assets";
import ImageDND from "./ImageDND";
import ImagesSlider from "./ImagesSlider";
import {
  ADD_ATTACHMENT_SHIPMENT_ITEMS,
  REMOVE_ATTACHMENT_SHIPMENT_ITEMS,
} from "src/constants/graphql/mutations";
import useMutation from "../../../hooks/useMutation";
import { useLoading } from "../../../components/capture/ReceivingWizard/Items/ItemDetails/LoadingProvider";

export default function ImagesItem({ asset }) {
  const { updateImage, bulkCreateImages, removeImage } = useAssets();
  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const list = orderBy(asset?.images || [], v => v.default, "desc");
  const [open, setOpen] = useState(null);
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);
  const params = useQueryParams();
  const shipmentItemId = params.get("shipmentItemId");
  const [addAttachmentShipmentItems] = useMutation(ADD_ATTACHMENT_SHIPMENT_ITEMS);
  const [removeAttachmentShipmentItems] = useMutation(REMOVE_ATTACHMENT_SHIPMENT_ITEMS);
  const { setLoading: setFormLoading } = useLoading();

  const handleOpen = () => {
    if (list.length > 0) {
      setOpen(true);
    }
  };

  const handleBulkCreateImages = async ({ variables, onSuccess, onFailure }) => {
    setFormLoading(true);
    try {
      await bulkCreateImages({
        variables: { ...variables, assetId: asset?.id },
        onSuccess,
        onFailure,
      });

      if (shipmentItemId) {
        await Promise.all(
          variables.images.map(image =>
            addAttachmentShipmentItems({
              variables: { file: image, shipmentItemId },
            })
          )
        );
      }
    } catch (error) {
      onFailure?.(error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleUpload = async images => {
    try {
      setLoading(true);
      await handleBulkCreateImages({
        variables: { assetId: asset.id, images },
        onSuccess: () => {
          setLoading(false);
        },
        onFailure: errors => {
          notifyError(errors);
          setLoading(false);
        },
      });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  const handleRemove = data => {
    setIndex(0);
    removeImage(data);
    if (shipmentItemId) {
      removeAttachmentShipmentItems({
        variables: { id: data.variables.id, shipmentItemId },
      });
    }
  };

  const handleDefaultUpload = e => {
    const files = Array.from(e.target.files);
    if (files.length) {
      handleUpload(files);
    }
  };

  return (
    <Box px={{ md: 5, xs: 2 }}>
      <Box width="100%" sx={{ aspectRatio: "5/4" }}>
        {loading ? (
          <Skeleton variant="rectangular" height="100%" width="100%" />
        ) : (
          <Box position="relative" onClick={handleOpen}>
            {list.length === 0 && <ImageDND onDrop={handleUpload} />}
            <ImagesSlider list={list} index={index} onChangeIndex={setIndex} />
          </Box>
        )}
      </Box>
      {open && list[index] && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={list[index].id}
          asset={asset}
          images={list}
          onClose={() => setOpen(false)}
          onRemove={handleRemove}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
      <Box mt={1.5}>
        <input
          type="file"
          accept="image/*"
          id="item-button"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleDefaultUpload}
        />
        <label htmlFor="item-button" style={{ width: "100%", margin: 0 }}>
          <Button fullwidth variant="outlined" color="primary" fullWidth component="span">
            <AddAPhotoIcon />
          </Button>
        </label>
      </Box>
    </Box>
  );
}
