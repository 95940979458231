import React, { forwardRef, useCallback, useRef } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import TableHeader, { MIN_WIDTH } from "../TableHeader";
import TableBody from "./TableBody";
import { visibleFieldsWidth } from "../utils";

const SIZES = {
  "x-large": { default: 2.5, fullScreen: 4 },
  large: { default: 5, fullScreen: 8 },
  medium: { default: 8, fullScreen: 10 },
  small: { default: 10, fullScreen: 12 }
};

const Table = ({
  fullScreen,
  size,
  disabledScroll,
  totalCount,
  order,
  loading,
  loadingFields,
  fields,
  fieldsProps,
  entities,
  onLoadMore,
  onChangeEntity,
  onChangeFields,
  onFillDown,
  onCopyPaste,
}) => {
  const tableWrapperRef = useRef(null);
  const { height } = useWindowDimensions(tableWrapperRef, [fullScreen]);
  const rowsCount = fullScreen ? SIZES[size].fullScreen : SIZES[size].default;
  const fieldsWidth = visibleFieldsWidth(fields, MIN_WIDTH);

  const InnerElementType = useCallback(
    forwardRef(({ style, children, ...rest }, ref) => (
      <div ref={ref} style={style} {...rest}>
        <TableHeader
          order={order}
          fields={fields}
          onChange={onChangeFields}
          fieldsProps={fieldsProps}
          style={{ width: fieldsWidth }}
        />
        {children}
      </div>
    )),
    [fields, fieldsProps, order, height, size]
  );

  if (loadingFields) {
    return (
      <div ref={tableWrapperRef} style={{ minHeight: 4 }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <TableBody
      size={size}
      totalCount={totalCount}
      fields={fields}
      fieldsProps={fieldsProps}
      entities={entities}
      onLoadMore={onLoadMore}
      onChangeEntity={onChangeEntity}
      rowsCount={rowsCount}
      height={height}
      innerRef={tableWrapperRef}
      InnerElementType={InnerElementType}
      loading={loading}
      disabledScroll={disabledScroll}
      fieldsWidth={fieldsWidth}
      onFillDown={onFillDown}
      onCopyPaste={onCopyPaste}
    />
  );
};

export default Table;
