import { useQuery } from "@apollo/react-hooks";
import { ASSETS_COLLECTION_TABLE_QUERY } from "src/constants/graphql/queries";

export const useAssetsCollection = variables => {
  const data = useQuery(ASSETS_COLLECTION_TABLE_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables,
  });

  const { loading, fetchMore } = data;
  const { metadata } = data?.data?.assetsCollection || {};

  const onLoadMore = () => {
    if (!metadata?.currentPage || loading) return;
    fetchMore({
      variables: { ...variables, page: metadata.currentPage + 1 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        const existingIds = new Set(
          previousResult.assetsCollection.collection.map(item => item.id)
        );
        const newItems = fetchMoreResult.assetsCollection.collection.filter(
          item => !existingIds.has(item.id)
        );

        if (newItems.length === 0) return previousResult;

        return {
          ...previousResult,
          assetsCollection: {
            ...previousResult.assetsCollection,
            collection: [...previousResult.assetsCollection.collection, ...newItems],
            metadata: fetchMoreResult.assetsCollection.metadata,
          },
        };
      },
    });
  };

  return [data, { onLoadMore }];
};
