import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Input from "src/components/form/Input";
import useMutation from "src/hooks/useMutation";
import { UPDATE_PROJECT_MUTATION } from "src/constants/graphql/mutations";
import useNotification from "src/hooks/notification";
import LogoUploadField from "./LogoUploadField";
import useAuth from "src/hooks/useAuth";

export default function ReportSettings({ project }) {
  const { user } = useAuth();
  const { notifySuccess, notifyError } = useNotification();
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION);
  const [loading, setLoading] = useState({
    partner: false,
    customer: false,
  });

  const handleLogoUpload = async (type, file) => {
    setLoading(prev => ({ ...prev, [type]: true }));
    try {
      const variables = {
        ...project,
      };

      if (variables.address && variables.address.__typename) {
        delete variables.address.__typename;
      }

      variables[type === "partner" ? "partnerLogoUrl" : "customerLogoUrl"] = file;

      await updateProject({
        variables,
        onCompleted: () => {
          notifySuccess(`${type === "partner" ? "Main" : "Customer"} logo updated successfully`);
        },
      });
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(prev => ({ ...prev, [type]: false }));
    }
  };

  const handleLogoRemove = async type => {
    setLoading(prev => ({ ...prev, [type]: true }));
    try {
      const variables = {
        ...project,
      };

      if (variables.address && variables.address.__typename) {
        delete variables.address.__typename;
      }

      variables[type === "partner" ? "removePartnerLogoUrl" : "removeCustomerLogoUrl"] = true;

      await updateProject({
        variables,
        onCompleted: () => {
          notifySuccess(`${type === "partner" ? "Partner" : "Customer"} logo removed successfully`);
        },
      });
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(prev => ({ ...prev, [type]: false }));
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Typography fontWeight={500}>Report Settings</Typography>
        <Grid container spacing={1} sx={{ pt: 1.5 }}>
          <Grid item xs={12}>
            <Input name="customerEmailSendList" label="Receiving Report Email Send List" />
          </Grid>
          <Grid item xs={12}>
            <Input name="osDEmailSendList" label="OS&D Email Send List" />
          </Grid>
          <Grid item xs={12}>
            <Input name="billingEmailSendList" label="Billing Email Send List" />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography fontWeight={400}>Report Logos</Typography>
          <Grid container spacing={1} sx={{ pt: 1.5 }}>
            <Grid item xs={12} md={4}>
              <LogoUploadField
                type="company"
                label="Main Logo"
                logoUrl={user.company.labelsLogoUrl}
                hideUploadButton={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LogoUploadField
                type="partner"
                label="Partner Logo"
                logoUrl={project.partnerLogoUrl}
                loading={loading.partner}
                onUpload={handleLogoUpload}
                onRemove={handleLogoRemove}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LogoUploadField
                type="customer"
                label={`Customer Logo`}
                logoUrl={project.customerLogoUrl}
                loading={loading.customer}
                onUpload={handleLogoUpload}
                onRemove={handleLogoRemove}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
