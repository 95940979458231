import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import camelCase from "lodash/camelCase";
import AssetItemLabel from "./AssetItemLabel";

export default function AssetItemDescription({ asset, order }) {
  const selectedField = order ? camelCase(order.split(".")[0]) : undefined;

  return (
    <Box ml="22px" overflow="hidden" display="flex" flexDirection="column" flexGrow={1}>
      {selectedField && <AssetItemLabel asset={asset} selectedField={selectedField} />}
      {selectedField !== "itemId" && (
        <Typography variant="body2" lineHeight={1.2} color="textSecondary" fontWeight={500}>
          {asset.itemId}
        </Typography>
      )}
      <Box width="100%" maxWidth="100%">
        <Typography
          style={{
            width: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          color="textSecondary"
          lineHeight={1.2}
          component="div"
          variant="body2"
        >
          {asset.description}
        </Typography>
      </Box>
    </Box>
  );
}
