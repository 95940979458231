import { gql } from "apollo-boost";
import * as fragment from "./fragments";

export const FETCH_USER = gql`
  query fetchUser($id: ID) {
    user(id: $id) {
      ${fragment.USER_FRAGMENT}
      clientIds
    }
  }
`;

export const FETCH_AUTH_USER_QUERY = gql`
  query {
    user {
      ${fragment.USER_FRAGMENT}
      stripePublicToken
      stripePayment
    }
  }
`;

export const FETCH_SHORT_USER = gql`
  query InvitationUser($accessToken: String) {
    invitationUser(accessToken: $accessToken) {
      email
      invitationAccepted
    }
  }
`;

export const ASSETS_COLLECTION_QUERY = gql`
  query GetAssetsCollection(
    $clientId: ID
    $projectId: ID
    $inventoryRequestId: ID
    $search: String
    $categories: [String!]
    $page: Int
    $limit: Int
    $storePage: Boolean
    $type: String
    $warehouseId: String
    $warehouseLocationName: String
    $hasImportedAssets: Boolean
    ) {
    assetsCollection(
      clientId: $clientId
      projectId: $projectId
      inventoryRequestId: $inventoryRequestId
      search: $search
      categories: $categories
      page: $page
      limit: $limit
      storePage: $storePage
      type: $type
      warehouseId: $warehouseId
      warehouseLocationName: $warehouseLocationName
      hasImportedAssets: $hasImportedAssets
      ) {
      collection {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
      }
    }
    categories(projectId: $projectId)
  }
`;

export const ASSETS_COLLECTION_TABLE_QUERY = gql`
  query GetAssetsTableCollection(
    $clientId: ID
    $projectId: ID
    $inventoryRequestId: ID
    $search: String
    $inventoryStatus: String
    $trackingStatus: String
    $categories: [String!]
    $page: Int
    $limit: Int
    $order: JSON
    $hasMissingItems: Boolean
    $storePage: Boolean
    $type: String
    $hasImportedAssets: Boolean
    ) {
    assetsCollection(
      clientId: $clientId
      projectId: $projectId
      inventoryRequestId: $inventoryRequestId
      inventoryStatus: $inventoryStatus
      trackingStatus: $trackingStatus
      search: $search
      categories: $categories
      page: $page
      limit: $limit
      order: $order
      hasMissingItems: $hasMissingItems
      storePage: $storePage
      type: $type
      hasImportedAssets: $hasImportedAssets
      ) {
      collection {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
        totalIds
      }
    }
  }
`;

export const ASSETS_PROJECT_IMAGES_QUERY = gql`
  query GetAssetsTableCollection(
    $clientId: ID
    $projectId: ID
    $inventoryRequestId: ID
    $search: String
    $inventoryStatus: String
    $trackingStatus: String
    $categories: [String!]
    $page: Int
    $limit: Int
    $order: JSON
    $hasMissingItems: Boolean
    $storePage: Boolean
    $type: String
    ) {
    assetsCollection(
      clientId: $clientId
      projectId: $projectId
      inventoryRequestId: $inventoryRequestId
      inventoryStatus: $inventoryStatus
      trackingStatus: $trackingStatus
      search: $search
      categories: $categories
      page: $page
      limit: $limit
      order: $order
      hasMissingItems: $hasMissingItems
      storePage: $storePage
      type: $type
      ) {
      collection {
        itemId
        manufacturer
        description
        disposition
        dimensions
        quantity
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
        totalIds
      }
    }
  }
`;

export const KITS_COLLECTION_TABLE_QUERY = gql`
  query KitsCollection(
    $projectId: ID
    $clientId: ID
    $search: String
    $page: Int
    $limit: Int
    $order: JSON
    ) {
    kitsCollection(
      projectId: $projectId
      clientId: $clientId
      search: $search
      page: $page
      limit: $limit
      order: $order
      ) {
      collection {
        ${fragment.KIT_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
        totalIds
      }
    }
  }
`;

export const KIT_ASSETS_QUERY = gql`
  query GetAsset($id: ID!) {
    asset(id: $id) {
      active
      ${fragment.ASSET_FRAGMENT}
      kitAssets {
        id
        kit {
          ${fragment.ASSET_FRAGMENT}
        } 
        asset {
          ${fragment.ASSET_FRAGMENT}
        }
        quantity
      }
    }
  }
`;

export const ASSETS_COLLECTION_RESTOCK_INVENTORY_QUERY = gql`
  query GetAssetsTableCollection(
     $projectId: ID
     $clientId: ID
     $search: String
     $type: String
    ) {
    assetsCollection(
      projectId: $projectId
      clientId: $clientId
      search: $search
      type: $type
      ) {
      collection {
        id
        itemId
        assetId
        rackLocations
        description
        model
        availableQuantity
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
      }
    }
  }
`;

export const LOOKUPS_QUERY = gql`
  query GetLookups($projectId: ID) {
    lookups(projectId: $projectId) {
      descriptions
      colors
      manufacturers
      dispositions
    }
  }
`;

export const CLIENTS_QUERY = gql`
  query getClients {
    clients {
      ${fragment.CLIENT_FRAGMENT}
    }
  }
`;

export const PROJECTS_QUERY = gql`
  query getProjects {
    projects(storePage: true) {
      ${fragment.PROJECT_FRAGMENT}
    }
  }
`;

export const CLIENTS_TABLE_QUERY = gql`
  query getClientsTable {
    clients(manage: true) {
      id
      name
      disabled
      active
      projectsCount
      createdAt
      projects {
        ${fragment.PROJECT_FRAGMENT}
      }
    }
  }
`;

export const PROJECTS_TABLE_QUERY = gql`
  query getProjectsTable {
    projects(manage: true) {
      ${fragment.PROJECT_FRAGMENT}
      client {
        active
        name
      }
    }
  }
`;

export const BARCODE_SCANS_QUERY = gql`
  query BarcodeScans($assetId: ID!) {
    barcodeScans(assetId: $assetId) {
      ${fragment.BARCODE_SCAN_FRAGMENT}
    }
  }
`;

export const USERS_TABLE_QUERY = gql`
  query {
    users {
      id
      email
      active
      name
      role
      createdAt
      clientsQuantity
      projectsQuantity
    }
  }
`;

export const CLIENT_USERS = gql`
  query GetClientUsers($clientId: ID!) {
    clientUsers(clientId: $clientId) {
      id
      email
      name
      role
      createdAt
    }
  }
`;

export const COMPANY_USERS_QUERY = gql`
  query {
    companyUsers {
      id
      email
      role
      name
    }
  }
`;

export const ASSET_BY_BARCODE_QUERY = gql`
  query AssetByBarcode($barcode: String, $projectId: ID, $clientId: ID) {
    asset(barcode: $barcode, projectId: $projectId, clientId: $clientId) {
       ${fragment.ASSET_FRAGMENT}
    }
  }
`;

export const ASSET_QUERY = gql`
  query GetAsset($id: ID!) {
    asset(id: $id) {
      active
      ${fragment.ASSET_FRAGMENT}
      ${fragment.ASSET_MEDICAL_FRAGMENT}
      nextAssetId
      prevAssetId
    }
  }
`;

export const FAVORITE_REPORTS_QUERY = gql`
  query FavoriteReports($projectId: ID, $clientId: ID) {
    favoriteReports(projectId: $projectId, clientId: $clientId) {
      ${fragment.FAVORITE_REPORTS_FRAGMENT}
    }
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query Notifications($limit: Int, $projectId: ID, $clientId: ID, $type: String, $ownerId: ID) {
    notifications(
      limit: $limit
      projectId: $projectId
      clientId: $clientId
      type: $type
      ownerId: $ownerId
    ) {
      id
      title
      client {
        name
      }
      project {
        name
      }
      message
      category
      link
      createdAt
      userEmail
    }
  }
`;

export const INVENTORY_REQUESTS_QUERY = gql`
  query InventoryRequests($limit: Int, $page: Int, $type: String, $projectId: Int, $search: String, $sortBy: [JSON!], $dateRange: JSON) {
    inventoryRequests(page: $page, limit: $limit, type: $type, projectId: $projectId, search: $search, sortBy: $sortBy, dateRange: $dateRange) {
      collection {
        ${fragment.DETAILED_INVENTORY_REQUEST_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
      }
    }
  }
`;

export const INVENTORY_REQUEST_QUERY = gql`
  query inventoryRequest($id: ID) {
    inventoryRequest(id: $id) {
      inventoryItems {
        rackLocations
        ${fragment.INVENTORY_ITEM_FRAGMENT}
      }
    }
  }
`;

export const INVENTORY_REQUESTS_WITH_PAGINATION_QUERY = gql`
  query InventoryRequests($limit: Int, $page: Int, $type: String,  $search: String, $sortBy: [JSON!], $dateRange: JSON) {
    inventoryRequests(page: $page, limit: $limit, type: $type, search: $search, sortBy: $sortBy, dateRange: $dateRange) {
      collection {
        ${fragment.DETAILED_INVENTORY_REQUEST_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
      }
    }
  }
`;

export const MOVE_MATRICES_QUERY = gql`
  query GetMoveMatrices($projectId: ID, $clientId: ID) {
    moveMatrices(projectId: $projectId, clientId: $clientId) {
      ${fragment.MOVE_MATRIX_FRAGMENT}
    }
  }
`;

export const SUBSCRIPTION_QUERY = gql`
  query {
    companySubscription {
      id
      status
      planStartAt
      planEndAt
      stripeCheckoutSessionId
      companyId
      companyPlan {
        id
        name
        amount
        description
      }
    }
  }
`;

export const COMPANY_PLANS_QUERY = gql`
  query {
    companyPlans {
      id
      name
      amount
      description
    }
  }
`;

export const SHOPPING_INVENTORY_REQUEST_QUERY = gql`
  query {
    shoppingInventoryRequest {
      ${fragment.SHOPPING_INVENTORY_REQUEST_FRAGMENT}
    }
  }
`;

export const SHOPPING_INVENTORY_REQUESTS_COLLECTION_QUERY = gql`
  query GetInventoryRequestsCollection(
    $page: Int
    $limit: Int
    ) {
    shoppingInventoryRequestsCollection(
      page: $page
      limit: $limit
      ) {
      collection {
        ${fragment.DETAILED_INVENTORY_REQUEST_FRAGMENT}
      }
      metadata {
        ${fragment.PAGINATION_FRAGMENT}
      }
    }
  }
`;

export const FETCH_PROJECT_QUERY = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      ${fragment.PROJECT_FRAGMENT}
    }
  }
`;

export const FETCH_CLIENT_QUERY = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      ${fragment.CLIENT_FRAGMENT}
      clientsWarehouses {
        warehouse {
          id
        }
      }
    }
  }
`;

export const SHOPPING_CART_INVENTORY_REQUEST_QUERY = gql`
  query GetShoppingInventoryRequest {
    shoppingInventoryRequest {
      ${fragment.SHOPPING_INVENTORY_REQUEST_FRAGMENT}
      assets {
        id
        assetId
        price
        photo
        description
        quantity
        availableQuantity
      }
    }
  }
`;

export const INVENTORY_TRANSACTIONS_QUERY = gql`
  query {
    inventoryTransactions {
      quantity
      createdAt
    }
  }
`;

export const ASSET_ATTACHMENTS_QUERY = gql`
  query GetAssetAttachments($assetId: ID!) {
    assetAttachments(assetId: $assetId) {
      ${fragment.ATTACHMENT_FRAGMENT}
    }
  }
`;

export const ACCESS_LINK_QUERY = gql`
  query GetAccessLink($id: ID!) {
    accessLink(id: $id) {
      ${fragment.ACCESS_LINK_FRAGMENT}
    }
  }
`;

export const ACCESS_LINKS_QUERY = gql`
  query {
    accessLinks {
      ${fragment.ACCESS_LINK_FRAGMENT}
    }
  }
`;

export const INVENTORY_REQUEST_ATTACHMENT_MUTATION = gql`
  query InventoryRequestAttachments($inventoryRequestId: ID!) {
    inventoryRequestAttachments(inventoryRequestId: $inventoryRequestId) {
      ${fragment.ATTACHMENT_FRAGMENT}
    }
  }
`;

export const ASSET_AUDIT_LOGS_QUERY = gql`
  query AssetAuditLogs($assetId: ID!, $action: String) {
    assetAuditLogs(assetId: $assetId, action: $action) {
      id
      itemId
      itemType
      details
      performedAt
      object
      performer {
        id
        email
      }
    }
  }
`;

export const ASSET_REPLAY_LOGS_QUERY = gql`
  query AssetReplayLogs($assetId: ID!) {
    assetReplayLogs(assetId: $assetId) {
      assetId
      performedAt
      user {
        email
      }
      availableQty
      reservedQty
      woNumber
      rackLocations
      quantity
      action
    }
  }
`;

export const GET_LOCATIONS_QUERY = gql`
  query GetLocations {
    locations {
      ${fragment.LOCATION_FRAGMENT}
    }
  }
`;

export const GET_REQUESTORS_QUERY = gql`
  query GetRequestors {
    requestors {
      ${fragment.REQUESTOR_FRAGMENT}
    }
  }
`;

export const GET_PLANS_QUERY = gql`
  query EquipmentPlannerFloorPlans($projectId: ID!, $clientId: ID!) {
    equipmentPlannerFloorPlans(projectId: $projectId, clientId: $clientId) {
      id
      name
      metadata
    }
  }
`;

export const GET_PLAN_QUERY = gql`
  #Query used to upload new plan
  query EquipmentPlannerFloorPlans($floorPlanId: ID!) {
    equipmentPlannerFloorPlan(floorPlanId: $floorPlanId) {
      id
      state
      iconSet {
        id
      }
      metadata
    }
  }
`;

export const GET_PLAN_QUERY_ADMIN = gql`
  #Query used to fetch plan for admin view
  query EquipmentPlannerFloorPlans($floorPlanId: ID!) {
    equipmentPlannerFloorPlan(floorPlanId: $floorPlanId) {
      id
      iconSet {
        id
      }
      metadata
    }
  }
`;

export const GET_PLAN_VIEWS_QUERY = gql`
  query EquipmentPlannerPlanViews($floorPlanId: ID!) {
    equipmentPlannerPlanViews(floorPlanId: $floorPlanId) {
      id
      name
      floorPlanState
      iconSetState
      accessLink {
        id
        token
      }
    }
  }
`;

export const WAREHOUSE_LOCATIONS = gql`
  query Warehouse($id: ID) {
    warehouse(id: $id) {
      name
      warehouseId
      warehouseLocations {
        ${fragment.WAREHOUSE_LOCATION}
      }
    }
  }
`;

export const WAREHOUSE_INFO = gql`
  query Warehouse($id: ID) {
    warehouse(id: $id) {
      name
      warehouseId
    }
  }
`;

export const WAREHOUSE_LOCATIONS_COLLECTION = gql`
  query WarehouseLocationsCollection($warehouseId: ID!, $search: String!, $projectIds: [ID!] $occupied: Boolean) {
    warehouseLocationsCollection(warehouseId: $warehouseId, search: $search, projectIds: $projectIds, occupied: $occupied) {
      collection {
        id
        locationId
        locationName
        zone
        locationType
        occupied
      }
    }
  }
`;

export const WAREHOUSE_LOCATION = gql`
  query WarehouseLocation($id: ID!) {
    warehouseLocation(id: $id) {
       ${fragment.WAREHOUSE_LOCATION_WITH_IMAGES}
    }
  }
`;

export const WAREHOUSE_NAMES = gql`
  query {
    warehouses {
      id
      warehouseId
      name
    }
  }
`;

export const ALL_WAREHOUSE_LOCATIONS = gql`
  query {
    warehouses {
      warehouseId
      name
      warehouseLocations {
        locationId
        locationName
      }
    }
  }
`;

export const WAREHOUSE_LOCATIONS_BY_CLIENT = gql`
  query GetClient($id: ID) {
    client(id: $id) {
      clientsWarehouses {
        warehouse {
          warehouseId
          name
          warehouseLocations {
            locationId
            locationName
          }
        }
      }
    }
  }
`;

export const SUSTAINABILITY_SHEET_DATA_QUERY = gql`
  query sustainabilitySheetData {
    sustainabilitySheetData {
      category
      subcategory
      unitCost
      unitWeight
      unitCo2
    }
  }
`;

export const PROJECT_BY_ID = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      users {
        id
        name
        email
        role
      }
    }
  }
`;

export const GET_SHIPMENT_QUERY = gql`
  query Shipment($id: ID!) {
    shipment(id: $id) {
      ${fragment.SHIPMENT_FRAGMENT}
      shipmentItems {
        ${fragment.SHIPMENT_ITEM_FRAGMENT}
      }
      overagesShortagesDamages {
        id
        osdType
        osdNotes
        osdQuantity
        shipmentItem {
          ${fragment.SHIPMENT_ITEM_FRAGMENT}
        }
        attachments {
          ${fragment.ATTACHMENT_FRAGMENT}
        }
      }
    }
  }
`;

export const GET_SHIPMENTS_QUERY = gql`
  query Shipments($projectId: ID!) {
    shipments(projectId: $projectId) {
      ${fragment.SHIPMENT_FRAGMENT}
    }
  }
`;
