import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import { Minus as MinusIcon, Plus as PlusIcon } from "react-feather";
import CircularProgress from "@mui/material/CircularProgress";

import useMutation from "../../../hooks/useMutation";
import { ADD_INVENTORY_ITEM_MUTATION } from "../../../constants/graphql/mutations";
import useQuery from "../../../hooks/useQuery";
import useNotification from "../../../hooks/notification";
import { useProjectInventory } from "../../../contexts/projectInventory";
import { useStyles } from "./AddToCart";

export default function AddToInventoryRequest({ asset, defaultValues, size = "medium" }) {
  const classes = useStyles({ size });
  const query = useQuery();
  const { projectId } = useParams();
  const {
    inventoryItems,
    updateInventoryItem,
    removeInventoryItem,
    reFetch,
  } = useProjectInventory();
  const { notifySuccess } = useNotification();
  const inventoryItem = inventoryItems[asset.id];
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(null);
  const inventoryRequestId = query.get("inventoryRequestId");
  const [addInventoryItem, { loading }] = useMutation(ADD_INVENTORY_ITEM_MUTATION);
  const notAvailable = asset.availableQuantity <= 0;

  const updateQuantity = useCallback(
    value => {
      if (value >= 0 && value <= asset.availableQuantity) {
        setQuantity(value);
      }
    },
    [asset.availableQuantity, setQuantity]
  );

  const handleMinus = useCallback(() => updateQuantity(quantity - 1), [quantity, updateQuantity]);

  const handlePlus = useCallback(() => updateQuantity(quantity + 1), [quantity, updateQuantity]);

  const handleUpdate = useCallback(
    event => updateQuantity(event.target.value ? parseInt(event.target.value, 10) : 0),
    [quantity, updateQuantity]
  );

  const handleAddInventoryItem = () => {
    if (quantity === 0 && !inventoryItem) return;

    const variables = {
      quantity,
      projectId,
      assetId: asset.id,
      inventoryRequestId,
      ...defaultValues,
    };

    return addInventoryItem({
      variables,
      onSuccess: ({ inventoryItem, asset }) => {
        setError(null);
        setQuantity(0);
        reFetch();
        inventoryItem
          ? updateInventoryItem(inventoryItem.id, inventoryItem)
          : removeInventoryItem(inventoryItem.id);
        notifySuccess("Asset successfully added");
      },
      onFailure: errors => {
        setError(errors.quantity || errors.asset || errors.submit);
      },
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={handleMinus}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
          <TextField
            disabled={notAvailable || loading}
            className={classes.quantityInput}
            color="secondary"
            variant="outlined"
            fullWidth
            type="number"
            size="small"
            inputProps={{ min: 0, step: 1 }}
            value={quantity === 0 ? "" : quantity}
            onChange={handleUpdate}
          />
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={handlePlus}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        </Box>
        <Button
          disabled={notAvailable || loading}
          size="small"
          variant="outlined"
          onClick={handleAddInventoryItem}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {loading && <CircularProgress size={16} />}
            Add
          </Box>
        </Button>
      </Box>
      {error && (
        <Box mt={1}>
          <FormHelperText htmlFor="barcode" error={!!error}>
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}
